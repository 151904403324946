import { Component, OnInit  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CommonServiceService } from 'src/app/common-service.service';
import { MessageafterdonationrequestComponent } from '../messageafterdonationrequest/messageafterdonationrequest.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-donation-request-form',
  templateUrl: './donation-request-form.component.html',
  styleUrls: ['./donation-request-form.component.css']
})
export class DonationRequestFormComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public fname: string = `Ramesh`;
  public lname: string = `Suresh`;
  public addCusForm: FormGroup;
  wasFormChanged = false;
  phoneNumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<DonationRequestFormComponent>,
    private service:CommonServiceService, private toast:ToastrService
  ) { }

  public ngOnInit(): void {
    this.addCusForm = this.fb.group({
      id: [0],
      name: ["", [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      address: ["", [Validators.required]],
      relation: ["", [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      contact: ["", [Validators.required, Validators.pattern(this.phoneNumber)]],
      email: ["", [Validators.required, Validators.email]],
    });
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAddCus(): void {
    this.markAsDirty(this.addCusForm);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

onSubmit() {

  if (this.addCusForm.invalid) 
  {
     //this.toast.warning('Please fill all details in mandatory fields');
    return;
  }
  else 
  {
      this.service.saveDonationRequest(this.addCusForm.value).subscribe(res=>{
          if(res){
            console.log("Saved: ",res);
            if(res.donationRequest){
              this.toast.success("Request saved.","SUCCESS");
              this.dialogRef.close();
               this.dialog.open(MessageafterdonationrequestComponent,{
                width: '300px',
                disableClose: false 
               });
            }
          }
      },error=>{
        this.toast.error("Something went wrong !");
      });
    }
  }
}
