import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonServiceService } from './../common-service.service';
import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatOption, MatPaginator, MatSelect, PageEvent } from '@angular/material';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css',
  "../../assets/vendor/bootstrap/css/bootstrap.min.css",
"../../assets/vendor/bootstrap-icons/bootstrap-icons.css",
"../../assets/vendor/boxicons/css/boxicons.min.css",
"../../assets/vendor/glightbox/css/glightbox.min.css",
"../../assets/vendor/remixicon/remixicon.css",
"../../assets/vendor/swiper/swiper-bundle.min.css",
"../../assets/css/style2.css"],
encapsulation: ViewEncapsulation.None,
})
export class BooksComponent implements OnInit {
 public limit: any = 10;
  public offset: any = 0;
  public searchString: any = "";
  public booksData:any=[];
  public books:any=[];
  public pageSize:any;
  public bookListCount: any;
  public val="";
  public allCat:any=[];
  public allWri:any=[];
  public filterType:any="";
  public filterKey:any=""
@ViewChild('choosedValue') matRef: MatSelect;
@ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private toast:ToastrService,private route:Router,private adminService:CommonServiceService,private sanitizer: DomSanitizer) {
    this.loadData();
    this.loadAllCatAndWri();
   
   }
  loadAllCatAndWri() {
  this.adminService.getAllCatAndWri().subscribe(res=>{
    //console.log(res);
    if(res){
      this.allCat=res.allCat;
      this.allWri=res.allWri;
     // console.log("All Cat: ",this.allCat[0][1]);
      //console.log("All Wri: ",this.allWri);
    }
  });
  }
   public getSantizeUrl(filePath,type) {
     let url='data:'+type+';base64,'+filePath;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}

pageEvent(event) {
 
  this.limit=event.pageSize;
  this.offset=event.pageIndex;

  this.loadData();
}

loadData(){
 this.adminService.getAllBooks(this.offset,this.limit,this.searchString,this.filterType,this.filterKey).subscribe(data=>{
        console.log("All Books: ",data);
        if(data){
          this.booksData=data.books.content;
          this.books=data.books;
          this.bookListCount = data.books.totalElements;
         // console.log(this.booksData);
       
          
        }
    },error=>{
      this.toast.error("Something went wrong !");
});
}
  ngOnInit() {
  }

  getImageURL(data){
    return "https://faizanetajushshariya.com/rashid/"+data;
  }

  viewPDFFile(data,id){
    window.open("https://faizanetajushshariya.com/rashid/"+data, '_blank');
    this.countView(id);
  }

  downloadPDFFile(data,name,id){
    const downloadLink = document.createElement("a");
    const fileName = name

    downloadLink.href = 'https://faizanetajushshariya.com/rashid/'+data;
    downloadLink.download = fileName;
    downloadLink.click();
    this.countDownload(id)
  }


   viewPDF(data,id){
    var type = 'application/pdf';
    let blob = null;
    const blobURL = URL.createObjectURL( this.pdfBlobConversion(data, 'application/pdf'));
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    
    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
    theDoc.body.appendChild(theScript);
    this.countView(id);
}
//converts base64 to blob type for windows
 pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || '';
      var sliceSize = 512;
      b64Data = b64Data.replace(/^[^,]+,/, '');
      b64Data = b64Data.replace(/\s/g, '');
      var byteCharacters = window.atob(b64Data);
      var byteArrays = [];

      for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

  downloadPDF(data,name,id){
    const downloadLink = document.createElement("a");
    const fileName = name

    downloadLink.href = 'data:application/pdf;base64,' +data;
    downloadLink.download = fileName;
    downloadLink.click();
    this.countDownload(id)
  }

  backtoHome(){
    this.route.navigateByUrl('/home').then(()=>{
        window.location.reload();
    });
  }
  printPage(){
      window.print();
  }
  countView(id){
        this.adminService.countViewBook(id).subscribe(res=>{
          //console.log(res);
        });
  }
  countDownload(id){
    this.adminService.countDownloadBook(id).subscribe(res=>{
     // console.log(res);
    });
  }


  filterSelection(val){
      if(val.value!=""){
        this.val=val.value;
        this.searchString="";
        this.limit=10;
        this.offset=0;
        this.paginator.pageIndex=0;
        this.paginator.pageSize=10;
      let label=val._selectionModel._selected[0].group.label;
      this.filterType=label;

      if(label=="By Categories"){
        let category=val._selectionModel._selected[0].value;
        console.log("category Selected: ",category);
        this.filterKey=category;
        this.loadData();
      }
      else{
        let writter=val._selectionModel._selected[0].value;
        console.log("Writter Selected: ",writter);
        this.filterKey=writter;
        this.loadData();
      }
      
    }
  }
  clear() {
    this.matRef.options.forEach((data: MatOption) => data.deselect());
    this.val="";
    this.filterType="";
    this.filterKey="";
    this.searchString="";
    this.limit=10;
    this.offset=0;
    this.paginator.pageIndex=0;
    this.paginator.pageSize=10;
    this.loadData();
  }
  transformValueWithCount(value){
    return value[0]+" ("+value[1]+")";
  }
  transformOnlyValue(value){
    return value[0];
  }
}
