import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-uploaddoc',
  templateUrl: './uploaddoc.component.html',
  styleUrls: ['./uploaddoc.component.css']
})
export class UploaddocComponent implements OnInit {

  constructor(private route:Router) {   let value = localStorage.getItem("Yes");
  console.log(value);
  if(value ==null || value==undefined || value=="" )
  {
    this.route.navigateByUrl('/login').then(()=>
    {
      window.location.reload()
    });
  }  }

  ngOnInit() {
  }

}
