import { Router } from '@angular/router';
import { DonationRequestFormComponent } from './../donation-request-form/donation-request-form.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-donation-options',
  templateUrl: './donation-options.component.html',
  styleUrls: ['./donation-options.component.css']
})
export class DonationOptionsComponent implements OnInit {
  submitted: boolean;

  donationOptions = new FormGroup({
    code: new FormControl('', [Validators.required]),
    
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<DonationOptionsComponent>,
    private toast:ToastrService,
    private snack:MatSnackBar,
    private route:Router
  ) { }

  ngOnInit() {
  }
  get f() {
    return this.donationOptions.controls;
  }

  request(){
    this.dialogRef.close();
    const dialogRef = this.dialog.open(DonationRequestFormComponent,{
      width: '650px',
       disableClose: false 
     });
  }

  onSubmit() {
    this.submitted = true;
    if (this.donationOptions.invalid) 
    {
       this.toast.warning('Please fill all details in mandatory fields');
      return;
    }
    else 
    {
      let code = this.donationOptions.controls.code.value;
      if(code.length!=6){
        this.toast.warning("Code must be of 6 digits.");
      }
      else if(code!='donrec'){
        this.toast.error("Wrong code !");
      }
      else{
        localStorage.setItem('val','1');
        this.toast.success("Code verified.","Success");
        setTimeout(() => {
          this.route.navigate(['/publicDonationRecord']).then(()=>{
          //  localStorage.setItem('val','1');
          });
      }, 4000); 
      
        this.snack.open("Redirecting you to donation record page...","", {
          duration: 4000,
        });
        this.dialogRef.close();

      }
    }
  }
}
