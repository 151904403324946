import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  constructor(private http: HttpClient) { }

APP_URL="https://faizanetajushshariyabackend.com/api/";
headers = new HttpHeaders({
});

// APP_URL="http://localhost:8080/api/";
// headers = new HttpHeaders({
// });


httpOption = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
httpOptions = {
       headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': 'true',
                    'mode': 'no-cors'
       })
     };
// success response handle
successResponse(response) {
  try {
    if (response) {
     // console.log("response:",response);
      return response.json().data;
    }
  } catch (ex) {
    ////console.log(ex);
  }
  return response;
}

// error handling
// error handling
errorHandler(error) {
 // console.log("error", error.text)
  try {
    return throwError(error.error);
  } catch (ex) {
    //console.log(ex);
  }
  // if (error) {
  //   return throwError(error);
  // } else {
  //   return throwError("The action could not be completed due to an internal error. Try the action again. If the error persists, contact your system administrator.");
  // }
}

createOrder(order): Observable<any> {
  return this.http.post(this.APP_URL + 'order', {
  customerName: order.name,
  email: order.email,
  phoneNumber: order.phone,
  amount: order.amount
  }, this.httpOption);
}

updateOrder(order): Observable<any> {
  return this.http.put(this.APP_URL + 'order', {
  razorpayOrderId: order.razorpay_order_id,
  razorpayPaymentId: order.razorpay_payment_id,
  razorpaySignature: order.razorpay_signature
  }, this.httpOption);
}



//Book Services----------------------------------------------------------------------------

saveBook(formData: any) {
  // let Url = this.APP_URL + "book/saveBook";
  return this.http.post('https://faizanetajushshariya.com/rashid/uploadbook.php', formData).pipe(
    map(response => {
     // console.log("Got response: ",response);
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllBooks(offset, limit,searchString,filterType,filterKey) {

  let Url = this.APP_URL + "book/getAllBooks";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString+ "&filterType=" + filterType+ "&filterKey=" + filterKey,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
getAllBooksForDashboard(offset, limit,searchString) {

  let Url = this.APP_URL + "book/getAllBooksForDashboard";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

countViewBook(id){
  let Url = this.APP_URL + "book/updateViewCount/"+id;
  return this.http.put(Url, {}).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


countDownloadBook(id){
  let Url = this.APP_URL + "book/updateDownloadCount/"+id;
  return this.http.put(Url, {}).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllCatAndWri() {

  let Url = this.APP_URL + "book/getAllCatAndWri";

  return this.http.get(Url).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


getBookById(id) {

  let Url = this.APP_URL + "book/getBookById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
deleteBookById(id) {

  let Url = this.APP_URL + "book/deleteBookById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
deleteBookData(idList){
  let formData=new FormData();
  for(let i=0;i<idList.length;i++){
    formData.append("id[]",idList[i]);
  }
  return this.http.post('https://faizanetajushshariya.com/rashid/deleteBookData.php', formData).pipe(
    map(response => {

      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteMultiSelectBookItems(data){
  let Url = this.APP_URL + "book/deleteBookRecords";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//---------------------------------------------------------------------------------------------

//Fatawa Services------------------------------------------------------------------------------

// saveFatawa(formData: any): Observable<any> {
//   let Url = this.APP_URL + "fatawa/saveFatawa";
//   return this.http.post(Url, formData).pipe(
//     map(response => {
//       return this.successResponse(response);
//     }),
//     catchError(this.errorHandler)
//   )
// }

saveFatawa(formData: any): Observable<any> {
  let Url = this.APP_URL + "fatawa/saveFatawa";
  return this.http.post('https://faizanetajushshariya.com/rashid/uploadFatawa.php', formData).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllFatawas(offset, limit,searchString) {

  let Url = this.APP_URL + "fatawa/getAllFatawas";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getFatawaById(id) {

  let Url = this.APP_URL + "fatawa/getFatawaById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
deleteFatawaById(id) {

  let Url = this.APP_URL + "fatawa/deleteFatawaById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteMultiSelectFatawaItems(data){
  let Url = this.APP_URL + "fatawa/deleteFatawaRecords";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllUnAnsweredQuestionsForFatawa() {

  let Url = this.APP_URL + "question/getAllUnAnsweredQuestionsForFatawa";

  return this.http.get(Url ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//---------------------------------------------------------------------------------------------------

//Donation Services ---------------------------------------------------------------------------------

saveDonationRecord(data): Observable<any> {
  let Url = this.APP_URL + "donation/saveDonationRecord";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
getAllDonationRecords(offset, limit,searchString,type) {

  let Url = this.APP_URL + "donation/getAllDonationRecords";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString + "&type=" + type,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getDonationRecordById(id,type) {

  let Url = this.APP_URL + "donation/getDonationRecordById";

  return this.http.get(Url+ "?id=" + id + "&type=" + type).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllDonationRecordByType(type) {

  let Url = this.APP_URL + "donation/getAllDonationRecordByType/"+type;

  return this.http.get(Url).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


deleteDonationRecordById(id,type) {

  let Url = this.APP_URL + "donation/deleteDonationRecordById";

  return this.http.get(Url+ "?id=" + id + "&type=" + type).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
deleteMultiSelectDonationItems(data,type){
  let Url = this.APP_URL + "donation/deleteDonationRecords/"+type;
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//---------------------------------------------------------------------------------------------------

// Question Services

saveQuestion(data): Observable<any> {
  let Url = this.APP_URL + "question/saveQuestion";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
verifyEmail(data): Observable<any> {
  let Url = this.APP_URL + "question/verifyEmail";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

searchQuestion(text) {

  let Url = this.APP_URL + "question/searchQuestion/";

  return this.http.get(Url+ text).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllAnsweredQuestions(offset, limit,searchString) {

  let Url = this.APP_URL + "question/getAllAnsweredQuestions";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllUnAnsweredQuestions(offset, limit,searchString) {

  let Url = this.APP_URL + "question/getAllUnAnsweredQuestions";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllRejectedQuestions(offset, limit,searchString) {

  let Url = this.APP_URL + "question/getAllRejectedQuestions";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

updateStatus(id,status) {

  let Url = this.APP_URL + "question/updateStatus/"+id+"/"+status;
  return this.http.get(Url).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

markAnswer(id) {

  let Url = this.APP_URL + "question/markAnswer/"+id;
  return this.http.get(Url).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


//----------------------Dashboard Services----------------------------------------------


getDashboardData() {

  let Url = this.APP_URL + "dashboard/getHomeData";

  return this.http.get(Url,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//--------------------------------------------------------------------------------------

//----------------------Donation request Services----------------------------------------------


saveDonationRequest(data) {

  let Url = this.APP_URL + "donationrequest/saveDonationRequestRecord";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllDonationRequests(offset, limit,searchString) {

  let Url = this.APP_URL + "donationrequest/getAllDonationRequestRecords";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

updateRequestStatus(id,who){
  let Url = this.APP_URL + "donationrequest/updateRequestStatus/"+id+"/"+who;
  return this.http.put(Url, {}).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//--------------------------------------------------------------------------------------

//--------------- Gallery Services-------------------------------------------------------

saveGalleryImages(formData: any) {
  // let Url = this.APP_URL + "book/saveBook";
  return this.http.post('https://gallery.faizanetajushshariya.com/uploadGalleryImages.php', formData).pipe(
    map(response => {
     // console.log("Got response: ",response);
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
saveGalleryDataAfterImages(list:any,category){
  
  let Url = this.APP_URL + "gallery/saveGallery/"+category;
  return this.http.post(Url, list).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllGalleryImages(offset, limit,searchString) {

  let Url = this.APP_URL + "gallery/getAllGalleryImages";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getGalleryImageId(id) {

  let Url = this.APP_URL + "gallery/getGalleryImageById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteGalleryData(idList){
  let formData=new FormData();
  for(let i=0;i<idList.length;i++){
    formData.append("id[]",idList[i]);
  }
  console.log(formData);
  return this.http.post('https://gallery.faizanetajushshariya.com/deleteGalleryData.php', formData).pipe(
    map(response => {

      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteGalleryImageById(id) {

  let Url = this.APP_URL + "gallery/deleteGalleryImageById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteMultiSelectGalleryImages(data){
  let Url = this.APP_URL + "gallery/deleteMultiSelectGalleryImages";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//---------------------------------------------------------------------------------------

//----------- Gallery category services -------------------------------------------------


saveGalleryCategory(data) {

  let Url = this.APP_URL + "gc/saveGalleryCategory";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllGalleryCategoriesWithPagination(offset, limit,searchString) {

  let Url = this.APP_URL + "gc/getAllGalleryCategoriesWithPagination";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString ,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllGalleryCategories() {

  let Url = this.APP_URL + "gc/getAllGalleryCategories";

  return this.http.get(Url,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteGalleryCategoryById(id) {

  let Url = this.APP_URL + "gc/deleteGalleryCategoryById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteMultiSelectGalleryCategories(data){
  let Url = this.APP_URL + "gc/deleteMultiSelectGalleryCategories";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


//Daily Islamic Knowledge Services----------------------------------------------------------------------------

saveImageForDailyIslamicKnowledge(formData: any) {
  // let Url = this.APP_URL + "book/saveBook";
  return this.http.post('https://dashboard.faizanetajushshariya.com/createDailyIslamicKnowledge.php', formData).pipe(
    map(response => {
     // console.log("Got response: ",response);
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

saveImageDataAfterImageUpload(url) {
  let Url = this.APP_URL + "dik/saveImage";
  return this.http.post(Url, url).pipe(
    map(response => {
     // console.log("Got response: ",response);
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllImages() {

  let Url = this.APP_URL + "dik/getAllImages";

  return this.http.get(Url,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
deleteImageById(id){

  let Url = this.APP_URL + "dik/deleteImageById/"+id;

  return this.http.get(Url,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

//--------------- Admission services-----------------------------

saveAdmission(formData: any) {
  return this.http.post('https://faizanetajushshariya.com/rashid/admission.php', formData).pipe(
    map(response => {
     // console.log("Got response: ",response);
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getStudentById(id) {

  let Url = this.APP_URL + "admission/getStudentById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

checkMobileNumberExistence(mnumber) {

  let Url = this.APP_URL + "admission/checkMobileNumberExistence/";

  return this.http.get(Url+ mnumber).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
checkAdhaarExistence(adhaar) {

  let Url = this.APP_URL + "admission/checkAdhaarExistence/";

  return this.http.get(Url+ adhaar).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteStudentById(id) {

  let Url = this.APP_URL + "admission/deleteStudentById/";

  return this.http.get(Url+ id).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllStudents(offset, limit,searchString) {

  let Url = this.APP_URL + "admission/getAllStudents";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteMultiSelectStudentItems(data){
  let Url = this.APP_URL + "admission/deleteStudentRecords";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

deleteStudentData(idList){
  let formData=new FormData();
  for(let i=0;i<idList.length;i++){
    formData.append("id[]",idList[i]);
  }
  return this.http.post('https://faizanetajushshariya.com/rashid/deleteStudentData.php', formData).pipe(
    map(response => {

      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

sendEmail(data){
  let Url = this.APP_URL + "admission/sendEmail";
  return this.http.post(Url, data).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
searchStudentBasedOnEID(val) {

  let Url = this.APP_URL + "admission/searchStudentBasedOnEID/";

  return this.http.get(Url+ val).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

 https = {
  //'responseType'  : 'arraybuffer' as 'json'
   'responseType'  : 'blob' as 'json'        //This also worked
};
downloadAdmitCard(data){
  let Url = this.APP_URL + "admission/downloadAdmitCard";
  return this.http.post<any>(Url,data, this.https).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


getAllStudentsByAdmitCardStatus(offset, limit,searchString) {

  let Url = this.APP_URL + "admission/getAllStudentsByAdmitCardStatus";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllStudentsByAdmissionStatus(offset, limit,searchString) {

  let Url = this.APP_URL + "admission/getAllStudentsByAdmissionStatus";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

getAllStudentsByResultStatus(offset, limit,searchString) {

  let Url = this.APP_URL + "admission/getAllStudentsByResultStatus";

  return this.http.get(Url+ "?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}
declareResult(array) {

  let Url = this.APP_URL + "admission/declareResult";

  return this.http.post(Url,array,this.httpOptions).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}


generateAdmitCards(idList,date){
  let Url = this.APP_URL + "admission/generateAdmitCards/";
  return this.http.post(Url+date, idList).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

updateAdmissionStatus(idList){
  let Url = this.APP_URL + "admission/updateAdmissionStatus";
  return this.http.post(Url, idList).pipe(
    map(response => {
      return this.successResponse(response);
    }),
    catchError(this.errorHandler)
  )
}

///////////////////////////////////////////////////
// Express REST API's

// getAllStudents(offset, limit,searchString) {

//   return this.http.get("https://faizanetajushshariyabackend.com/getAllStudents.php?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString).pipe(
//     map(response => {
//       return this.successResponse(response);
//     }),
//     catchError(this.errorHandler)
//   )
// }

// deleteStudentById(id) {
//   return this.http.get("https://faizanetajushshariyabackend.com/deleteStudentById.php?id="+id).pipe(
//     map(response => {
//       return this.successResponse(response);
//     }),
//     catchError(this.errorHandler)
//   )
// }

// getAllStudentsByAdmitCardStatus(offset, limit,searchString) {


//   return this.http.get("https://faizanetajushshariyabackend.com/getAllStudentsByAdmitCardStatus.php?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString).pipe(
//     map(response => {
//       return this.successResponse(response);
//     }),
//     catchError(this.errorHandler)
//   )
// }

// generateAdmitCards(idList,date){
//   return this.http.post("https://faizanetajushshariyabackend.com/generateAdmitCards.php?date=" + date, { student_ids: idList }).pipe(
//     map(response => {
//       return this.successResponse(response);
//     }),
//     catchError(this.errorHandler)
//   )
// }

// getAllStudentsByAdmissionStatus(offset, limit,searchString) {

//   return this.http.get("https://faizanetajushshariyabackend.com/getAllStudentsByAdmissionStatus.php?offset=" + offset + "&limit=" + limit + "&searchString=" + searchString).pipe(
//     map(response => {
//       return this.successResponse(response);
//     }),
//     catchError(this.errorHandler)
//   )
// }


}