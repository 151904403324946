import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper, MatSnackBar, MatStep } from '@angular/material';

@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.css']
})
export class AskQuestionComponent implements OnInit {

  isLinear = true;
  submitted: boolean;
  submitted2: boolean;
  otpData:any;

  phoneNumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  otp = /^(\+\d{1,3}[- ]?)?\d{6}$/;

  askQuestionForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required,Validators.email]),
    contact: new FormControl('', [Validators.required,Validators.pattern(this.phoneNumber)]),
    address: new FormControl('', [Validators.required]),
    question:new FormControl('', [Validators.required]),
  });
  verifyOTP = new FormGroup({
    otp: new FormControl('', [Validators.required,Validators.pattern(this.otp)])
  }); 

  constructor(private snack:MatSnackBar,private service:CommonServiceService,private toast:ToastrService,@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AskQuestionComponent>){
      }

  ngOnInit() {
  }

  get f() {
    return this.askQuestionForm.controls;
  }
  get o() {
    return this.verifyOTP.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.submitted2 = true;
    if (this.askQuestionForm.invalid) {
      return;
    }
    else {
          this.service.saveQuestion(this.askQuestionForm.value).subscribe(res=>{
              if(res){
                console.log("Question Submitted: ",res);
                this.toast.success("Question Submitted Successfully.","SUCCESS");
                this.dialogRef.close({data:this.askQuestionForm.value});

              }
          },error=>{
            this.toast.error("Something went wrong !");
      });
    }
  }
  verifyEmail(stepper:MatStepper){
    this.snack.open("Sending OTP ...","", {
      duration: 5000,
    });
    console.log(this.askQuestionForm.value);
    this.service.verifyEmail(this.askQuestionForm.value).subscribe(res=>{
          console.log("Email: ",res);
          if(res){
            this.otpData=res.OTP;
            this.snack.open("OTP sent to Email.","", {
              duration: 3000,
            });
            stepper.next();
          }
    },error=>{
      this.toast.error("Something went wrong !");
});
    
  }
  checkOTP(stepper:MatStepper){
    let otp=this.verifyOTP.controls['otp'].value;
    if(this.otpData==otp){
      this.toast.success('OTP verified.','SUCCESS');
      stepper.next();
    }
    else{
      this.toast.error("Invalid OTP.","ERROR");
    }

  }

}
