import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from '../common-service.service';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {

  constructor(private snack:MatSnackBar,private service:CommonServiceService,private toast:ToastrService,@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<TermAndConditionComponent>){
      }

  ngOnInit() {
    
  }

  agree(){
    this.dialogRef.close({data:true});
  }

}
