import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';

@Component({
  selector: 'app-donationrequests',
  templateUrl: './donationrequests.component.html',
  styleUrls: ['./donationrequests.component.css']
})
export class DonationrequestsComponent implements OnInit {

  public limit: any = 5;
  public donationListCount: any;
  public pageSize:any;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public donationList: any = [];
  displayedColumns: string[] = ["name", "contact","relation","approved","rejected","address","actions"];
  
  constructor(private toast:ToastrService,private route:Router,private service:CommonServiceService) 
  {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
   }

  ngOnInit() {
    
  }
  refreshData(){
    this.limit=5;
    this.offset=0;
    this.donationList =[];
    this.refresh();
     
  }

  refresh(){
    this.service.getAllDonationRequests(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("Donations: ",data);
          this.donationList = data.getAllDonationRequestRecords.content;
          this.ELEMENT_DATA = this.donationList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.donationListCount = data.getAllDonationRequestRecords.totalElements;
          this.toast.info("Data Refreshed.",'SUCCESS');
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }

  loadData(){
    this.service.getAllDonationRequests(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("Donations: ",data);
          this.donationList = data.getAllDonationRequestRecords.content;
          this.ELEMENT_DATA = this.donationList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.donationListCount = data.getAllDonationRequestRecords.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }

  pageEvent(event) {
 
    this.limit=event.pageSize;
    this.offset=event.pageIndex;
    this.loadData();
  }

  check(obj,who)
  {
      if(who=="a"){
        this.approveRequest(obj);
      }
      else{
        this.rejectRequest(obj);
      }
  }

  approveRequest(obj)
  {
    if(obj.rejected==true){
      this.toast.warning("Already rejected !");
    }
    else if(obj.approved==true){
      this.toast.warning("Already Approved !");
    }
    else{

      this.service.updateRequestStatus(obj.id,"a").subscribe(res=>{
        if(res){
            this.toast.success("Request Approved.","SUCCESS");
          console.log("approve status: ",res);
          this.refreshData();
        }
      });
      
    }
      
  }

  rejectRequest(obj)
  {
    if(obj.rejected==true){
      this.toast.warning("Already rejected !");
    }
    else if(obj.approved==true){
      this.toast.warning("Already Approved !");
    }
    else{
      this.service.updateRequestStatus(obj.id,"r").subscribe(res=>{
        if(res){
          this.toast.success("Request rejected.","SUCCESS");
          console.log("reject status: ",res);
          this.refreshData();
        }
      });
    }
   
  }


}
