import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent, MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-creategallerycategory',
  templateUrl: './creategallerycategory.component.html',
  styleUrls: ['./creategallerycategory.component.css']
})
export class CreategallerycategoryComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [];


  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];


  public limit: any = 15;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public pageSize:any;
  public categoryList: any = [];
  displayedColumns: string[] = ["select","name","created_date","actions"];
  public categoryListCount: any;




  submitted: boolean;
   createGalleryCategory = new FormGroup({
    id: new FormControl(0),
    name: new FormControl('', [Validators.required]),

  });


  constructor(private dialog: MatDialog,private route:Router,private toast:ToastrService,private adminService:CommonServiceService,private sanitizer: DomSanitizer) 
  { 
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
  }

  ngOnInit() {
  
  }
  get f() {
    return this.createGalleryCategory.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.createGalleryCategory.invalid) {
        return;
    }
    else {
      console.log(this.createGalleryCategory.value)
      var listOfObjects = [];
      this.fruits.forEach(function(entry) 
      {
      var singleObj = {};
      singleObj['id'] = 0;
      singleObj['name'] = entry.name;
      listOfObjects.push(singleObj);
      });
      console.log(listOfObjects);
        this.adminService.saveGalleryCategory(listOfObjects).subscribe(res=>{
            if(res){
              console.log("GC saved: ",res);
              this.toast.success("Category saved successfully.","SUCCESS");
              if(this.selection.hasValue()){
                this.selection.clear();
              }
              this.fruits=[];
              this.createGalleryCategory.controls.name.setValue('');
             
              this.loadData();
            }
        });
    }
  }
  refreshData(){
    this.limit=15;
    this.offset=0;
    this.categoryList=[];
    this.refresh();
  }
  refresh(){
    this.adminService.getAllGalleryCategoriesWithPagination(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("Categories: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.categoryList = data.gc.content;
        this.ELEMENT_DATA = this.categoryList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.categoryListCount = data.gc.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
 
  loadData(){
    this.adminService.getAllGalleryCategoriesWithPagination(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("Categories: ",data);
          this.categoryList = data.gc.content;
          this.ELEMENT_DATA = this.categoryList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.categoryListCount = data.gc.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }






  deleteRecord(element){

    this.adminService.deleteGalleryCategoryById(element.id).subscribe(data=>{
      if(data){
        this.toast.success('Record deleted successfully.','SUCCESS');
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.loadData();
       
      }
    },error=>{
      this.toast.error('Something went wrong !','ERROR')
    })
  }

  pageEvent(event) {
 
    if(this.selection.hasValue()){
      this.selection.clear();
    }
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       this.deleteRecord(element);
      }
    });
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
deleteSelection(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForDeletion(this.records);
}
confirmForDeletion(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete selected items?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteMultiSelect(records);
    }
    else{
      if(this.selection.hasValue()){
        this.selection.clear();
      }
    }
  });
}
deleteMultiSelect(data){
  this.adminService.deleteMultiSelectGalleryCategories(data).subscribe(res=>{
      if(res){
        this.toast.success('Records deleted successfully.','SUCCESS');
        this.selection.clear();
        this.loadData();
      }
  });
}




add(event: MatChipInputEvent): void {
  const input = event.input;
  const value = event.value;

  // Add our fruit
  if ((value || '').trim()) {
    this.fruits.push({name: value.trim()});
  }

  // Reset the input value
  if (input) {
    input.value = '';
  }
}

remove(fruit: Fruit): void {
  const index = this.fruits.indexOf(fruit);

  if (index >= 0) {
    this.fruits.splice(index, 1);
  }

  console.log(this.fruits.length)
  if(this.fruits.length==0){
    this.createGalleryCategory.controls['name'].setValue("");
  }
  console.log(this.fruits);
  
}
}

