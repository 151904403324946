import { element } from 'protractor';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-viewbooks',
  templateUrl: './viewbooks.component.html',
  styleUrls: ['./viewbooks.component.css']
})
export class ViewbooksComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];


  public limit: any = 15;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public pageSize:any;
  public booksList: any = [];
  displayedColumns: string[] = ["select","name", "category", "language", "pages","description","writtenBy","actions"];
  public bookListCount: any;

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
   }

  ngOnInit() {
    
  }
  refreshData(){
    this.limit=15;
    this.offset=0;
    this.booksList=[];
    this.refresh();
  }
  refresh(){
    this.service.getAllBooksForDashboard(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("Books: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.booksList = data.books.content;
        this.ELEMENT_DATA = this.booksList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.bookListCount = data.books.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
 
  loadData(){
    this.service.getAllBooksForDashboard(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("Books: ",data);
          this.booksList = data.books.content;
          this.ELEMENT_DATA = this.booksList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.bookListCount = data.books.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
  viewBook(element){
    window.open("https://faizanetajushshariya.com/rashid/"+element.bookFile, '_blank');

  }
  viewBook2(element){

    var type = 'application/pdf';
    let blob = null;
    const blobURL = URL.createObjectURL( this.pdfBlobConversion(element.bookFile, 'application/pdf'));
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
        window.print();
    }
    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
    theDoc.body.appendChild(theScript);
    //injectThis();
}
//converts base64 to blob type for windows
 pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || '';
      var sliceSize = 512;
      b64Data = b64Data.replace(/^[^,]+,/, '');
      b64Data = b64Data.replace(/\s/g, '');
      var byteCharacters = window.atob(b64Data);
      var byteArrays = [];

      for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
  deleteBook(element){
    let idsList=[];
    idsList.push(element.id)
    this.service.deleteBookData(idsList).subscribe(data=>{
      if(data){
        this.service.deleteBookById(element.id).subscribe(res=>{

            if(res){
          this.toast.success('Book deleted successfully.','SUCCESS');
          if(this.selection.hasValue()){
            this.selection.clear();
          }
          this.loadData();
        }
        });
        
       
      }
    },error=>{
      this.toast.error('Something went wrong !','ERROR')
    })
  }
  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
 
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       this.deleteBook(element);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
deleteSelection(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForDeletion(this.records);
}
confirmForDeletion(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete selected items?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteMultiSelect(records);
    }
  });
}
deleteMultiSelect(data){
  let idsList=[];
  idsList= data.map(a => a.id);
  console.log("multi book data:",data)
  this.service.deleteMultiSelectBookItems(idsList).subscribe(res=>{
      if(res){
        this.toast.success('Records deleted successfully.','SUCCESS');
        this.selection.clear();
        this.loadData();
      }
  });
}

}
