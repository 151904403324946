import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { CommonServiceService } from '../common-service.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-donationpublicview',
  templateUrl: './donationpublicview.component.html',
  styleUrls: ['./donationpublicview.component.scss']
})
export class DonationpublicviewComponent implements OnInit {


  public limit: any = 10;
  public limit2: any = 10;
  public donationListCount: any;
  public pageSize:any;
  public donationListCount2: any;
  public pageSize2:any;
  public offset: any = 0;
  public offset2: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public ELEMENT_DATA2: any;
  public dataSource: any;
  public dataSource2: any;
  public donationList: any = [];
  public donationList2: any = [];
  displayedColumns: string[] = ["name", "year", "amount", "address"];

  constructor(public dialog: MatDialog,private router:Router,private service: CommonServiceService,
    private toast:ToastrService) 
    {
      this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects 
        ) {
            localStorage.removeItem("val");
            this.router.navigateByUrl('/donate').then(()=>{
              this.toast.error("Session expired !");
            });
        }
      });
        let value = localStorage.getItem("val");
        console.log(value);
        if(value ==null || value==undefined || value=="" )
        {
          this.router.navigateByUrl('/donate');
        }
        else{
        this.loadData();
        this.loadData2();
      }

   
  }

  ngOnInit() {
    let value = localStorage.getItem("val");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
        {
          this.router.navigateByUrl('/donate');
        }
  }

  loadData(){
    this.service.getAllDonationRecords(this.offset,this.limit,this.searchString,'English').subscribe(data=>{
        if(data){
          console.log("Donations: ",data);
          this.donationList = data.donations.content;
          this.ELEMENT_DATA = this.donationList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.donationListCount = data.donations.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }

  loadData2(){
    this.service.getAllDonationRecords(this.offset2,this.limit2,this.searchString,'Urdu').subscribe(data=>{
        if(data){
          console.log("Donations: ",data);
          this.donationList2 = data.donations.content;
          this.ELEMENT_DATA2 = this.donationList2;
          this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
          this.donationListCount2 = data.donations.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
  pageEvent(event) {
 
  
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  pageEvent2(event) {
 
  
    this.limit2=event.pageSize;
    this.offset2=event.pageIndex;

    this.loadData();
  }

    home(){
    this.router.navigateByUrl('/home').then(()=>{
        window.location.reload();
    });
}
login(){
  this.router.navigateByUrl('/login');
}
refreshData(){
  this.limit=5;
  this.offset=0;
  this.donationList =[];
  this.refresh();
   
}
refreshData2(){
  this.limit2=5;
  this.offset2=0;
  this.donationList2 =[];
  this.refresh2();
   
}

refresh(){
  this.service.getAllDonationRecords(this.offset,this.limit,this.searchString,'English').subscribe(data=>{
      if(data){
        console.log("Donations: ",data);
        
        this.donationList = data.donations.content;
        this.ELEMENT_DATA = this.donationList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.donationListCount = data.donations.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}

refresh2(){
  this.service.getAllDonationRecords(this.offset2,this.limit2,this.searchString,'Urdu').subscribe(data=>{
      if(data){
        console.log("Donations: ",data);
        
        this.donationList2 = data.donations.content;
        this.ELEMENT_DATA2 = this.donationList2;
        this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
        this.donationListCount2 = data.donations.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
@HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
    localStorage.removeItem("val");
  }

}
