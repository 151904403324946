import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { log } from 'console';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];


  public limit: any = 15;
  public offset: any = 0;
  public searchString: any = "2024";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public pageSize:any;
  public studentList: any = [];
  displayedColumns: string[] = ["select","enrollment_number","name","fname","mname","address","dob","mnumber","jurisprudence",
  "adhaar","adhar_pdf_file","s_class","result","remarks","admisson_status","admit_card_generated","created_date","actions"];
  public studentListCount: any;

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
   }

  ngOnInit() {
    
  }
  refreshData(){
    this.limit=15;
    this.offset=0;
    this.studentList=[];
    this.loadData();
    this.toast.info("Data Refreshed.",'SUCCESS');
  }
  refresh(){
    this.service.getAllStudents(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("students: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.studentList = data.students.content;
        this.ELEMENT_DATA = this.studentList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.studentListCount = data.students.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
 
  loadData(){
    this.service.getAllStudents(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("students: ",data);
          this.studentList = data.students.content;
          this.ELEMENT_DATA = this.studentList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.studentListCount = data.students.totalElements;

          // this.studentList = data.students;
          // this.ELEMENT_DATA = this.studentList;
          // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          // this.studentListCount = data.totalRecords;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
  viewPDF(element){
    window.open("https://faizanetajushshariya.com/rashid/"+element.adharPdfFile, '_blank');

  }


  deleteStudent(element){
    let idsList=[];
    idsList.push(element.id)
    this.service.deleteStudentData(idsList).subscribe(data=>{
      if(data){
        this.service.deleteStudentById(element.id).subscribe(res=>{

            if(res.success == true){
              console.log(res);
              
          this.toast.success('Student deleted successfully.','SUCCESS');
          if(this.selection.hasValue()){
            this.selection.clear();
          }
          this.loadData();
        }
        else{
          this.toast.error('Something went wrong !','ERROR')
        }
        },error=>{
          this.toast.error('Something went wrong !','ERROR')
        });
        
       
      }
    },error=>{
      this.toast.error('Something went wrong !','ERROR')
    })
  }

  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
 
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       this.deleteStudent(element);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
deleteSelection(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForDeletion(this.records);
}
confirmForDeletion(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete selected items?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteMultiSelect(records);
    }
  });
}
deleteMultiSelect(data){
  let idsList=[];
  idsList= data.map(a => a.id);
  console.log("multi book data:",data);
  this.service.deleteStudentData(idsList).subscribe(data=>{
    if(data){
        this.service.deleteMultiSelectStudentItems(idsList).subscribe(res=>{
         if(res){
            this.toast.success('Records deleted successfully.','SUCCESS');
            this.selection.clear();
            this.loadData();
           }
  } );
}});
}

replaceAdharValue(value){
  console.log(value);
  return value.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join("-");
}

filterByClass(value){
  this.limit=15;
  this.offset=0;
  if(this.paginator != null){
   
  this.paginator.pageIndex=0;
  this.paginator.pageSize=15;
  }
     this.searchString = value;
     this.loadData();
}

}
