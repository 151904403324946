import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardhomeComponent } from './dashboardhome/dashboardhome.component';
import { UploadbookComponent } from './uploadbook/uploadbook.component';
import { UploadfatawaComponent } from './uploadfatawa/uploadfatawa.component';
import { UploaddocComponent } from './uploaddoc/uploaddoc.component';
import { ViewbooksComponent } from './viewbooks/viewbooks.component';
import { ViewfatawasComponent } from './viewfatawas/viewfatawas.component';
import { ViewdocsComponent } from './viewdocs/viewdocs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule, MatInputModule, MatSelectModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatPaginatorModule, MatTableModule, MatExpansionModule, MatListModule, MatProgressBarModule, MatProgressSpinnerModule, MatBadgeModule, MatChipsModule, MatDatepickerModule } from '@angular/material';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { DonationenglishComponent } from './donationenglish/donationenglish.component';
import { DonationurduComponent } from './donationurdu/donationurdu.component';
import { HttpClientModule } from '@angular/common/http';
import { ViewDonationEnglishComponent } from './view-donation-english/view-donation-english.component';
import { ViewDonationUrduComponent } from './view-donation-urdu/view-donation-urdu.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ViewQuestionsComponent } from './view-questions/view-questions.component';
import { AnsweredQuestionsComponent } from './answered-questions/answered-questions.component';
import { UnansweredQuestionsComponent } from './unanswered-questions/unanswered-questions.component';
import { RejectedQuestionsComponent } from './rejected-questions/rejected-questions.component';
import { DonationrequestsComponent } from './donationrequests/donationrequests.component';
import { UploadGalleryImagesComponent } from './upload-gallery-images/upload-gallery-images.component';
import { SeeGalleryImagesComponent } from './see-gallery-images/see-gallery-images.component';
import { CreategallerycategoryComponent } from './creategallerycategory/creategallerycategory.component';
import { CreatedailyislamicknowlegeComponent } from './createdailyislamicknowlege/createdailyislamicknowlege.component';
import { StudentsComponent } from './students/students.component';
import { AdmissionRegistrationComponent } from './admission-registration/admission-registration.component';
import { GenerateAdmitCardsComponent } from './generate-admit-cards/generate-admit-cards.component';
import { DeclareResultComponent } from './declare-result/declare-result.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatTableModule, MatPaginatorModule,
    MatFormFieldModule,
    MatRadioModule,
    MaterialFileInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatChipsModule,
    MatDatepickerModule,
    BrowserAnimationsModule
  ],
  declarations: [DashboardComponent,DashboardhomeComponent, UploadbookComponent, UploadfatawaComponent, UploaddocComponent, ViewbooksComponent, ViewfatawasComponent, ViewdocsComponent, DonationenglishComponent, DonationurduComponent, ViewDonationEnglishComponent, ViewDonationUrduComponent, ConfirmDialogComponent, ViewQuestionsComponent, AnsweredQuestionsComponent, UnansweredQuestionsComponent, RejectedQuestionsComponent, DonationrequestsComponent, UploadGalleryImagesComponent, SeeGalleryImagesComponent, CreategallerycategoryComponent, CreatedailyislamicknowlegeComponent, StudentsComponent, AdmissionRegistrationComponent, GenerateAdmitCardsComponent, DeclareResultComponent]
})
export class DashboardModule { }
