import { CommonServiceService } from './../common-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search-question',
  templateUrl: './search-question.component.html',
  styleUrls: ['./search-question.component.css']
})
export class SearchQuestionComponent implements OnInit {
  submitted: boolean;
  searchResult:any=[];

  searchQuestion = new FormGroup({
    searchText: new FormControl('', [Validators.required]),

  });

  constructor(private service:CommonServiceService,private toast:ToastrService,@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SearchQuestionComponent>){
      }

  ngOnInit() {
  }
  get f() {
    return this.searchQuestion.controls;
  }
  close(){
    this.dialogRef.close();

  }

   onSubmit() {
    this.submitted = true;

    if (this.searchQuestion.invalid) {
      return;
    }
    else {
            this.service.searchQuestion(this.searchQuestion.controls['searchText'].value).subscribe(res=>{
                if(res){
                  console.log(res);
                  this.searchResult=res.searchQuestion;
                }
            },error=>{
              this.toast.error("Something went wrong !");
        });
      }
      }

}
