import { ViewQuestionsComponent } from './view-questions/view-questions.component';
import { ViewDonationUrduComponent } from './view-donation-urdu/view-donation-urdu.component';
import { ViewbooksComponent } from './viewbooks/viewbooks.component';
import { ViewfatawasComponent } from './viewfatawas/viewfatawas.component';
import { DonationurduComponent } from './donationurdu/donationurdu.component';
import { DonationenglishComponent } from './donationenglish/donationenglish.component';
import { UploadfatawaComponent } from './uploadfatawa/uploadfatawa.component';
import { UploadbookComponent } from './uploadbook/uploadbook.component';

import { DashboardComponent } from './dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardhomeComponent } from './dashboardhome/dashboardhome.component';
import { ViewDonationEnglishComponent } from './view-donation-english/view-donation-english.component';
import { DonationrequestsComponent } from './donationrequests/donationrequests.component';
import { SeeGalleryImagesComponent } from './see-gallery-images/see-gallery-images.component';
import { UploadGalleryImagesComponent } from './upload-gallery-images/upload-gallery-images.component';
import { CreategallerycategoryComponent } from './creategallerycategory/creategallerycategory.component';
import { CreatedailyislamicknowlegeComponent } from './createdailyislamicknowlege/createdailyislamicknowlege.component';
import { AdmissionRegistrationComponent } from './admission-registration/admission-registration.component';
import { StudentsComponent } from './students/students.component';
import { GenerateAdmitCardsComponent } from './generate-admit-cards/generate-admit-cards.component';
import { DeclareResultComponent } from './declare-result/declare-result.component';

const routes: Routes = [
  {
      path: 'dashboard',
      component: DashboardComponent,
      children: [
        {
          path: 'home',
          component: DashboardhomeComponent
        }
        ,{
          path: 'uploadBook',
          component: UploadbookComponent
        }
        ,{
          path: 'uploadFatawa',
          component: UploadfatawaComponent
        }
        ,{
          path: 'uploadDonationRecordEnglish',
          component: DonationenglishComponent
        }
        ,{
          path: 'uploadDonationRecordUrdu',
          component: DonationurduComponent
        }
        ,{
          path: 'viewFatawaList',
          component: ViewfatawasComponent
        },
        {
          path: 'viewBookList',
          component: ViewbooksComponent
        }
        ,
        {
          path: 'viewDonationEnglish',
          component: ViewDonationEnglishComponent
        }
        ,
        {
          path: 'viewDonationUrdu',
          component: ViewDonationUrduComponent
        },
        {
          path: 'viewQuestions',
          component: ViewQuestionsComponent
        },
        {
          path: 'seeDonationRequests',
          component: DonationrequestsComponent
        },
        {
          path: 'viewGalleryImages',
          component: SeeGalleryImagesComponent
        },
        {
          path: 'uploadGalleryImages',
          component: UploadGalleryImagesComponent
        }
        ,
        {
          path: 'createGalleryCategory',
          component: CreategallerycategoryComponent
        },
        {
          path: 'createDailyIslamicKnowledge',
          component: CreatedailyislamicknowlegeComponent
        },{
          path:'viewRegisteredStudents',
          component:StudentsComponent
        },{
          path:'registerStudentForAdmission',
          component:AdmissionRegistrationComponent
        }
        ,{
          path:'generateStudentAdmitCards',
          component:GenerateAdmitCardsComponent
        },
        {
          path:'declareResult',
          component:DeclareResultComponent
        }

        
      ] 
    }
    ,
];

@NgModule({
 imports: [RouterModule.forChild(routes)],
 exports: [RouterModule],
 })
export class DashboardRoutingModule { }
