import { SearchQuestionComponent } from './../search-question/search-question.component';
import { TermAndConditionComponent } from './../term-and-condition/term-and-condition.component';
import { AskQuestionComponent } from './../ask-question/ask-question.component';
import { CommonServiceService } from 'src/app/common-service.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { QuestionInfoAfterSubmittionComponent } from '../question-info-after-submittion/question-info-after-submittion.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-fatawa-list',
  templateUrl: './fatawa-list.component.html',
  styleUrls: ['./fatawa-list.component.css']
})
export class FatawaListComponent implements OnInit {

  todayNumber: number = Date.now();
  todayDate : Date = new Date();
  todayString : string = new Date().toDateString();
  todayISOString : string = new Date().toISOString();

  submitted: boolean;

  public pageSize:any;
  public fatawasList: any = [];
  public limit: any = 10;
  public offset: any = 0;
  public fatawaListCount: any;
  public searchString: any = "";

  searchFatawa = new FormGroup({
    searchText: new FormControl('', [Validators.required]),

  });

  constructor(private dialog:MatDialog,private router:Router,private service:CommonServiceService,private toast:ToastrService) {
    this.loadData();
   }

   

  ngOnInit() {
  }
  get f() {
    return this.searchFatawa.controls;
  }

  home(){
      this.router.navigateByUrl('/home').then(()=>{
          window.location.reload();
      });
  }
  login(){
    this.router.navigateByUrl('/login');
}

pageEvent(event) {
  this.limit=event.pageSize;
  this.offset=event.pageIndex;

  this.loadData();
}

loadData(){
  this.service.getAllFatawas(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("Fatawas: ",data);
        this.fatawasList = data.fatawas.content;
        this.fatawaListCount = data.fatawas.totalElements;
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
viewFatawa(element){

  var type = 'application/pdf';
  let blob = null;
  const blobURL = URL.createObjectURL( this.pdfBlobConversion(element.fatawaFile, 'application/pdf'));
  const theWindow = window.open(blobURL);
  const theDoc = theWindow.document;
  const theScript = document.createElement('script');
  function injectThis() {
      window.print();
  }
  theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
  theDoc.body.appendChild(theScript);
  //injectThis();
}
//converts base64 to blob type for windows
pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  downloadPDF(data){
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = 'https://faizanetajushshariya.com/rashid/'+data.fatawaFile;
    downloadLink.download = data.fatawaFileName.split('.')[0];
    downloadLink.click();
  }
  askQuestion() {
    const dialogRef = this.dialog.open(TermAndConditionComponent,{
      disableClose: true,
      width: '300px',
    });
  
    dialogRef.afterClosed().subscribe(res=>{
        if(res.data==true){
          this.openQuestionDialog();
        }
    });
  }


  openQuestionDialog() {
    const dialogRef = this.dialog.open(AskQuestionComponent,{
      disableClose: true,
      width: '550px',
    });
  
    dialogRef.afterClosed().subscribe(res=>{
      if(res.data){
        let userData=res.data;
        this.openInfoDialog(userData);
      }
  });
  }
  openInfoDialog(userData) {
    const dialogRef = this.dialog.open(QuestionInfoAfterSubmittionComponent,{
      disableClose: true,
      width: '300px',
      data: {
        userData: userData
      }
    });
  
    dialogRef.afterClosed().subscribe(res=>{
     
  });
  }
  searchQuestion(){
    const dialogRef = this.dialog.open(SearchQuestionComponent,{
      disableClose: true,
      width: 'auto',
      height:'auto',
    });
  
    dialogRef.afterClosed().subscribe(res=>{

  });
  }

  search() {
    this.submitted = true;

    if (this.searchFatawa.invalid) {
      return;
    }
    else {
           
      }
      }
      reset() {
        this.searchFatawa.reset();
      }

}
