import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';

@Component({
  selector: 'app-donationurdu',
  templateUrl: './donationurdu.component.html',
  styleUrls: ['./donationurdu.component.css']
})
export class DonationurduComponent implements OnInit {

  submitted: boolean;


  uploadDonationForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    type:new FormControl('Urdu'),
  });

  constructor(private toast:ToastrService,private service:CommonServiceService,private route:Router) { 
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
  }

  ngOnInit() {
 
  }

  get f() {
    return this.uploadDonationForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.uploadDonationForm.invalid) {
      return;
    }
    else {
      console.log(this.uploadDonationForm.value);
      this.service.saveDonationRecord(this.uploadDonationForm.value).subscribe(data=>
        { 
          if(data)
          {
            this.route.navigateByUrl('/dashboard/viewDonationUrdu').then(()=>{
              this.toast.success('Donation Record saved successfully.','SUCCESS');
            });
          }
        },error=>{
              this.toast.error("Something went wrong !");
        });
      
    }
  }
 


}
