import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-info-after-submittion',
  templateUrl: './question-info-after-submittion.component.html',
  styleUrls: ['./question-info-after-submittion.component.css']
})
export class QuestionInfoAfterSubmittionComponent implements OnInit {

  userData:any;
  constructor(private dialogRef:MatDialogRef<QuestionInfoAfterSubmittionComponent>,
    @Inject(MAT_DIALOG_DATA) private data:any) 
    { 

    }

  ngOnInit() {
    if(this.data){
      this.userData=this.data.userData;
      console.log(this.userData);
    }
  }
  

}
