import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',
//   '../../assets/dashboard/css/style.css',
//   '../../assets/dashboard/css/jquery.mCustomScrollbar.css',
// '../../assets/dashboard/pages/waves/css/waves.min.css',
// '../../assets/dashboard/css/bootstrap/css/bootstrap.min.css',
// '../../assets/dashboard/icon/themify-icons/themify-icons.css',
// '../../assets/dashboard/css/font-awesome-n.min.css',
// '../../assets/dashboard/css/style.min.css',
// '../../assets/dashboard/pages/notification/notification.css',
// '../../assets/dashboard/css/component.css']
  ]
  ,encapsulation: ViewEncapsulation.None, 
})
export class DashboardComponent implements OnInit {
  
  public showPage="Home";;
  
  constructor(private router:Router,private toast:ToastrService,private route: ActivatedRoute) 
  { 
   // console.log(this.route.snapshot.queryParamMap);
  //  let value = localStorage.getItem("Yes");
  //       console.log(value);
  //       if(value ==null || value==undefined || value=="" )
  //       {
  //         this.router.navigateByUrl('/login').then(()=>
  //         {
  //           window.location.reload()
  //         });
  //       }
    const snapshot: RouterStateSnapshot = router.routerState.snapshot;
    console.log(snapshot.url);
    let url = snapshot.url;
    let n = url.lastIndexOf('/');
    let result = url.substring(n + 1);
    if(result=="home"){
      this.showPage="Home";
    }
    else if(result=="uploadBook"){
      this.showPage="Upload Book";
    }
    else if(result=="uploadFatawa"){
      this.showPage="Upload Fatawa";
      console.log("Book Panel");
    }
    else if(result=="uploadDonationRecordEnglish"){
      this.showPage="Upload Donation Record";
    }
    else if(result=="uploadDonationRecordUrdu"){
      this.showPage="Upload Donation Record";
    }
    else if(result=="viewFatawaList"){
      this.showPage="Fatawa List";
    }
    else if(result=="viewBookList"){
    this.showPage="Books List"
    }
    else if(result=="viewDonationEnglish"){
      this.showPage="Donation List"
      }
      else if(result=="viewDonationUrdu"){
        this.showPage="Donation List"
        }
        else if(result=="viewRegisteredStudents"){
          this.showPage="View Students";
        }
        else if(result=="registerStudentForAdmission"){
          this.showPage="Admission Registration";
        }

        else if(result=="seeDonationRequests"){
          this.showPage="Donation Record Request";
        }
        else if(result=="viewGalleryImages"){
          this.showPage="Gallery Images";
        }
        else if(result=="uploadGalleryImages"){
          this.showPage="Upload Images";
        }
        else if(result=="createGalleryCategory"){
          this.showPage="Create Category";
        }
        else if(result=="createDailyIslamicKnowledge"){
          this.showPage="Islamic Knowledge";
        }
        else if(result=="generateStudentAdmitCards"){
          this.showPage="Generate Admit Cards";
        }
        else if(result=="declareResult"){
          this.showPage="Declare Result";
        }
        
       
  
  }

  ngOnInit(){
  }
  

  logOut()
  {
    let loginSession =localStorage.getItem('Yes');
    if(loginSession!=undefined || loginSession!=null){
//       var cant = document.styleSheets.length
// for(var i=0;i<cant;i++){
//     document.styleSheets[i].disabled=true;
// }

// Array.prototype.forEach.call(document.styleSheets, function(element){
//   try{
//     element.disabled = true;
//   }catch(err){}
// });
      localStorage.removeItem('Yes');
      this.router.navigateByUrl('/home').then(()=>{
        this.logOutMsg();
        // const node = document.createElement('script');
        // node.src = '../../assets/js/main.js';
        // node.type = 'text/javascript';
        // node.async = false;
        // // node.id = 'main';
        // node.charset = 'utf-8';
        // document.getElementsByTagName('head')[0].appendChild(node);

        // this.removejscssfile('../../assets/dashboard/js/jquery-ui/jquery-ui.min.js','js');
        // this.removejscssfile('../../assets/dashboard/js/popper.js/popper.min.js ','js');
        // this.removejscssfile('../../assets/dashboard/js/jquery.mCustomScrollbar.concat.min.js','js');
        // this.removejscssfile('../../assets/dashboard/js/bootstrap/js/bootstrap.min.js ','js');
        // this.removejscssfile('../../assets/dashboard/pages/waves/js/waves.min.js','js');
        // this.removejscssfile('../../assets/dashboard/js/jquery-slimscroll/jquery.slimscroll.js','js');
        

      });
    }
    else{
      this.toast.error('Something went wrong !.','ERROR')
    }
  }

  logOutMsg(){
    this.toast.success('Logout Successfully.','SUCCESS');
  }
   removejscssfile(filename, filetype){
    var targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" //determine element type to create nodelist from
    var targetattr=(filetype=="js")? "src" : (filetype=="css")? "href" : "none" //determine corresponding attribute to test for
    var allsuspects=document.getElementsByTagName(targetelement)
    for (var i=allsuspects.length; i>=0; i--){ //search backwards within nodelist for matching elements to remove
    if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i].getAttribute(targetattr).indexOf(filename)!=-1)
        allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
    }
}
 

changeRoute(url){
  console.log(url);
  this.router.navigateByUrl(url).then(()=>{
    console.log("route Changed");
    let n = url.lastIndexOf('/');
    let result = url.substring(n + 1);
    console.log(result);
    if(result=="home"){
      this.showPage="Home";
    }
    else if(result=="uploadBook"){
      this.showPage="Upload Book";
      console.log("Book Panel");
    }
    else if(result=="uploadFatawa"){
      this.showPage="Upload Fatawa";
    }
    else if(result=="uploadDonationRecordEnglish"){
      this.showPage="Upload Donation English";
    }
    else if(result=="uploadDonationRecordUrdu"){
      this.showPage="Upload Donation Urdu";
    }
    else if(result=="viewFatawaList"){
      this.showPage="Fatawa List";
    }
    else if(result=="viewBookList"){
    this.showPage="Books List";
    }
    else if(result=="viewDonationEnglish"){
      this.showPage="Donation List"
      }
      else if(result=="viewRegisteredStudents"){
        this.showPage="View Students";
      }
      else if(result=="registerStudentForAdmission"){
        this.showPage="Admission Registration";
      }

      else if(result=="viewDonationUrdu"){
        this.showPage="Donation List"
        }
        else if(result=="seeDonationRequests"){
          this.showPage="Donation Record Request";
        }
        else if(result=="viewGalleryImages"){
          this.showPage="Gallery Images";
        }
        else if(result=="uploadGalleryImages"){
          this.showPage="Upload Images";
        }
        else if(result=="createGalleryCategory"){
          this.showPage="Create Category";
        }
        else if(result=="createDailyIslamicKnowledge"){
          this.showPage="Islamic Knowledge";
        }
        else if(result=="generateStudentAdmitCards"){
          this.showPage="Generate Admit Cards";
        }
        else if(result=="declareResult"){
          this.showPage="Declare Result";
        }
        
  });
}


}
