import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { CommonServiceService } from './../../common-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { error } from 'console';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-viewfatawas',
  templateUrl: './viewfatawas.component.html',
  styleUrls: ['./viewfatawas.component.css']
})
export class ViewfatawasComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public limit: any = 10;
  public fatawaListCount: any;
  public pageSize:any;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public fatawasList: any = [];
  displayedColumns: string[] = ["subject", "category","actions"];
  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>{
        window.location.reload();
      });
    } 
    this.loadData();
   }

  ngOnInit() {
    
  }
  refreshData(){
    this.limit=10;
    this.offset=0;
    this.fatawasList =[];
    this.refresh();
  }
  refresh(){
    this.service.getAllFatawas(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("Fatawas: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.fatawasList = data.fatawas.content;
        this.ELEMENT_DATA = this.fatawasList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.fatawaListCount = data.fatawas.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
  }

  loadData(){
    this.service.getAllFatawas(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("Fatawas: ",data);
          this.fatawasList = data.fatawas.content;
          this.ELEMENT_DATA = this.fatawasList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.fatawaListCount = data.fatawas.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
  viewFatawa(element){
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = 'https://faizanetajushshariya.com/rashid/'+element.fatawaFile;
    downloadLink.download = element.fatawaFileName.split('.')[0];
    downloadLink.click();
  }



//   viewFatawa(element){

//     var type = 'application/pdf';
//     let blob = null;
//     const blobURL = URL.createObjectURL( this.pdfBlobConversion(element.fatawaFile, 'application/pdf'));
//     const theWindow = window.open(blobURL);
//     const theDoc = theWindow.document;
//     const theScript = document.createElement('script');
//     function injectThis() {
//         window.print();
//     }
//     theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
//     theDoc.body.appendChild(theScript);
//     //injectThis();
// }
//converts base64 to blob type for windows
 pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || '';
      var sliceSize = 512;
      b64Data = b64Data.replace(/^[^,]+,/, '');
      b64Data = b64Data.replace(/\s/g, '');
      var byteCharacters = window.atob(b64Data);
      var byteArrays = [];

      for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
  deleteFatawa(element){

    this.service.deleteFatawaById(element.id).subscribe(data=>{
      if(data){
        this.toast.success('Fatawa deleted successfully.','SUCCESS');
        this.loadData();
       
      }
    },error=>{
      this.toast.error('Something went wrong !','ERROR')
    })
  }

  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }

  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       this.deleteFatawa(element);
      }
    });
  }

  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
deleteSelection(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForDeletion(this.records);
}
confirmForDeletion(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete selected items?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteMultiSelect(records);
    }
  });
}
deleteMultiSelect(data){
  this.service.deleteMultiSelectFatawaItems(data).subscribe(res=>{
      if(res){
        this.toast.success('Records deleted successfully.','SUCCESS');
        this.selection.clear();
        this.loadData();
      }
  });
}
copyDownloadLink(element){
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = 'https://faizanetajushshariya.com/rashid/'+element.fatawaFile;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.toast.info("Link Copied.","COPIED");
}
}
