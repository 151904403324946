import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from '../common-service.service';

import jsPDF from 'jspdf';

@Component({
  selector: 'app-downloadadmitcard',
  templateUrl: './downloadadmitcard.component.html',
  styleUrls: ['./downloadadmitcard.component.css']
})
export class DownloadadmitcardComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;

  public studentList:any;
  public flag:any;
  constructor(private toast:ToastrService,private service:CommonServiceService) { }

  ngOnInit() {
  }

  onSubmit(event: any) {
    let val=event.target.enumber.value;
    if(val==""){
      this.toast.info("Please enter your enrolled number !");
      this.studentList= null;
      this.flag=false;
    }
    else{
      this.service.searchStudentBasedOnEID(val).subscribe(res=>{
        if(res){
          console.log("search student: ",res);

          if(res.searchStudentBasedOnEID!=null){
            this.studentList=res.searchStudentBasedOnEID;
            console.log("student list: ",this.studentList);
            this.flag=false;
          }
          else{
            this.flag=true;
            this.studentList=null;
          }
         
        }
      },err=>{
        this.toast.warning("Something went wrong !");
      });
      //this.toast.success("Success");
    }
 }

 downloadAdmitCard(studentList){
  
  this.service.downloadAdmitCard(studentList).subscribe(res=>{
  //   let file = new Blob([res], { type: 'application/pdf' });            
  // var fileURL = URL.createObjectURL(file);
  // window.open(fileURL);

  let dataType = res.type;
  let binaryData = [];
  binaryData.push(res);
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
  if (studentList)
      downloadLink.setAttribute('download', studentList.name);
  document.body.appendChild(downloadLink);
  downloadLink.click();


  });
 }

}
