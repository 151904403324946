import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import * as XLSX from 'xlsx'; 
declare const require: any;
require('jspdf-autotable');

const { jsPDF } = require("jspdf");

@Component({
  selector: 'app-view-donation-urdu',
  templateUrl: './view-donation-urdu.component.html',
  styleUrls: ['./view-donation-urdu.component.css']
})
export class ViewDonationUrduComponent implements OnInit {

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];
  recordForExcel: any=[];
  public limit: any = 10;
  public donationListCount: any;
  public pageSize:any;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public donationList: any = [];
  displayedColumns: string[] = ["select","name", "year", "amount", "address","actions"];

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
   }

  ngOnInit() {
    
  }
  refreshData(){
    this.limit=10;
    this.offset=0;
    this.donationList=[];
    this.refresh();
     
  }
 
  refresh(){
    this.service.getAllDonationRecords(this.offset,this.limit,this.searchString,'Urdu').subscribe(data=>{
      if(data){
        console.log("Donations: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.donationList = data.donations.content;
        this.ELEMENT_DATA = this.donationList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.donationListCount = data.donations.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
  }

  loadData(){
    this.service.getAllDonationRecords(this.offset,this.limit,this.searchString,'Urdu').subscribe(data=>{
        if(data){
          console.log("Donations: ",data);
          this.donationList = data.donations.content;
          this.ELEMENT_DATA = this.donationList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          console.log(this.dataSource);
          this.donationListCount = data.donations.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
 
  deleteRecord(element){

    this.service.deleteDonationRecordById(element.id,'Urdu').subscribe(data=>{
      if(data){
        this.toast.success('Record deleted successfully.','SUCCESS');
        this.loadData();
       
      }
    },error=>{
      this.toast.error('Something went wrong !','ERROR')
    })
  }

  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       this.deleteRecord(element);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
deleteSelection(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForDeletion(this.records);
}
confirmForDeletion(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete selected items?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteMultiSelect(records);
    }
  });
}
deleteMultiSelect(data){
  this.service.deleteMultiSelectDonationItems(data,'Urdu').subscribe(res=>{
      if(res){
        this.toast.success('Records deleted successfully.','SUCCESS');
        this.selection.clear();
        this.loadData();
      }
  });
}

exportExcel(){
  this.service.getAllDonationRecordByType("Urdu").subscribe(res=>{
    if(res){
     
      this.recordForExcel=res.data;
      const workBook = XLSX.utils.book_new(); // create a new blank book
const workSheet = XLSX.utils.json_to_sheet(this.recordForExcel);

XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
XLSX.writeFile(workBook, 'donationUrdu.xlsx'); // initiate a file download in browser
    }
  });
}
exportPDF(){
  const doc = new jsPDF('l', 'mm', 'a4'); 
  var col = ["Name", "Amount","Year","Address"];
  var rows = [];
  this.service.getAllDonationRecordByType("Urdu").subscribe(res=>{
    if(res){
      this.recordForExcel=res.data;
      
   this.recordForExcel.forEach(element => {      
    var temp = [element.name,element.amount,element.year,element.address];
    rows.push(temp);

    });        

     doc.autoTable(col, rows);
      doc.save('donationUrdu.pdf');
  
}
  });
}

search(value){

  if(value==""){
this.toast.info("Write something to search.");
  }
  else{
    console.log("Search value: ",value);
    this.searchString=value;
    this.loadData();
  }
}

clearSearch(){
  this.searchString="";
  this.limit=10;
  this.offset=0;
  this.paginator.pageIndex=0;
  this.paginator.pageSize=10;
  this.loadData();
}


}
