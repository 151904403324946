import { ConfirmDialogComponent } from './dashboard/confirm-dialog/confirm-dialog.component';
import { SpinnerOverlayServiceService } from './spinner-overlay-service.service';
import { CommonServiceService } from './common-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlmaktabAlNoorComponent } from './almaktab-al-noor/almaktab-al-noor.component';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { DashboardModule } from './dashboard/dashboard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardhomeComponent } from './dashboard/dashboardhome/dashboardhome.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BooksComponent } from './books/books.component';
import { BookDetailsComponent } from './book-details/book-details.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { SpinnerInterceptor } from './spinner-interceptor';
import { FatawaListComponent } from './fatawa-list/fatawa-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { MatFormFieldModule } from '@angular/material';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { QuestionInfoAfterSubmittionComponent } from './question-info-after-submittion/question-info-after-submittion.component';
import { SearchQuestionComponent } from './search-question/search-question.component';
import { PaymentpageComponent } from './paymentpage/paymentpage.component';
import { DonationRequestFormComponent } from './donation-request-form/donation-request-form.component';
import { DonationOptionsComponent } from './donation-options/donation-options.component';
import { DonationpublicviewComponent } from './donationpublicview/donationpublicview.component';
import { MessageafterdonationrequestComponent } from './messageafterdonationrequest/messageafterdonationrequest.component';
import { AdmissionComponent } from './admission/admission.component';
import { DownloadadmitcardComponent } from './downloadadmitcard/downloadadmitcard.component';
import { SearchStudentComponent } from './search-student/search-student.component';
import { ViewResultComponent } from './view-result/view-result.component';
// import { HashLocationStrategy,LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LandingComponent,
    AlmaktabAlNoorComponent,
    BooksComponent,
    BookDetailsComponent,
    SpinnerOverlayComponent,
    FatawaListComponent,
    AskQuestionComponent,
    TermAndConditionComponent,
    QuestionInfoAfterSubmittionComponent,
    SearchQuestionComponent,
    PaymentpageComponent,
    DonationRequestFormComponent,
    DonationOptionsComponent,
    DonationpublicviewComponent,
    MessageafterdonationrequestComponent,
    AdmissionComponent,
    DownloadadmitcardComponent,
    SearchStudentComponent,
    ViewResultComponent
  ],
  entryComponents: [SpinnerOverlayComponent,ConfirmDialogComponent,AskQuestionComponent,TermAndConditionComponent,
    QuestionInfoAfterSubmittionComponent,SearchQuestionComponent,DonationRequestFormComponent,DonationOptionsComponent,
    MessageafterdonationrequestComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule ,
    AppRoutingModule,
    DashboardModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MaterialFileInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatTooltipModule ,
    MatFormFieldModule,
    ToastrModule.forRoot()

  ],
  providers: [CommonServiceService,SpinnerOverlayServiceService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}],
    // { provide:LocationStrategy,useClass:PathLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
