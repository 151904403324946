import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from './../common-service.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit {

  public bookId:any;
  public bookData:any;
  public bookCover: any;
  constructor(private toast:ToastrService,private router:Router,private route:ActivatedRoute,private service:CommonServiceService,private sanitizer: DomSanitizer) {
     this.route.params.subscribe(params => {
      this.bookId = params['id'];
      if(this.bookId){
          this.service.getBookById(this.bookId).subscribe(data=>{
            //console.log("Book By ID: ",data);
            if(data){
              this.bookData=data.bookById;
              this.getSantizeUrl(this.bookData);
              console.log(this.bookData)
            }
          },error=>{
            this.toast.error("Something went wrong !");
      });
      }
      });
   }

  ngOnInit() {
  }
  public getSantizeUrl(data) {
    let url="https://faizanetajushshariya.com/rashid/"+data.bookCoverFile;
   this.bookCover=url;
}
viewPDFFile(data){
  window.open("https://faizanetajushshariya.com/rashid/"+data, '_blank');
}

downloadPDFFile(data,name){
  const downloadLink = document.createElement("a");
  const fileName = name

  downloadLink.href = 'https://faizanetajushshariya.com/rashid/'+data;
  downloadLink.download = fileName;
  downloadLink.click();
}



viewFatawa(data){
  var type = 'application/pdf';
  let blob = null;
  const blobURL = URL.createObjectURL( this.pdfBlobConversion(data, 'application/pdf'));
  const theWindow = window.open(blobURL);
  const theDoc = theWindow.document;
  const theScript = document.createElement('script');
  function injectThis() {
      window.print();
  }
  theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
  theDoc.body.appendChild(theScript);
}
//converts base64 to blob type for windows
pdfBlobConversion(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

downloadFatawa(data,name){
  const downloadLink = document.createElement("a");
  const fileName = name

  downloadLink.href = 'data:application/pdf;base64,' +data;
  downloadLink.download = fileName;
  downloadLink.click();
}
home(){
  this.router.navigateByUrl('/home').then(()=>{
      window.location.reload();
  });
}
}
