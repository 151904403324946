import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-generate-admit-cards',
  templateUrl: './generate-admit-cards.component.html',
  styleUrls: ['./generate-admit-cards.component.css']
})
export class GenerateAdmitCardsComponent implements OnInit {
  submitted: boolean;
  maxDob: Date;
  minDate = new Date();

  formFlag=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];

  admitCard = new FormGroup({
    examDate: new FormControl('', [Validators.required])
  });
  public limit: any = 500;
  public offset: any = 0;
  public searchString: any = "2024";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public pageSize:any;
  public studentList: any = [];
  displayedColumns: string[] = ["select","enrollment_number","name","fname","mname","address","dob","mnumber","jurisprudence","email",
  "adhaar","adhar_pdf_file","s_class","admisson_status","admit_card_generated","created_date"];
  public studentListCount: any;

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
   
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    const today = new Date();
    this.maxDob = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    this.loadData();
   }

  ngOnInit() {
    
  }
  get f() {
    return this.admitCard.controls;
  }

  refreshData(){
    this.limit=500;
    this.offset=0;
    this.studentList=[];
    this.refresh();
  }
  refresh(){
    this.service.getAllStudentsByAdmitCardStatus(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("students: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.studentList = data.students.content;
        this.ELEMENT_DATA = this.studentList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.studentListCount = data.students.totalElements;
        // this.studentList = data.students;
        //   this.ELEMENT_DATA = this.studentList;
        //   this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        //   this.studentListCount = data.totalRecords;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
 
  loadData(){
    this.service.getAllStudentsByAdmitCardStatus(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("students: ",data);
          this.studentList = data.students.content;
          this.ELEMENT_DATA = this.studentList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.studentListCount = data.students.totalElements;
          // this.studentList = data.students;
          // this.ELEMENT_DATA = this.studentList;
          // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          // this.studentListCount = data.totalRecords;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
  viewPDF(element){
    window.open("https://faizanetajushshariya.com/rashid/"+element.adharPdfFile, '_blank');

  }


  
  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
 
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
  generateAdmitCards(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForAdmitCards(this.records);
}
confirmForAdmitCards(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure ?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.generateMultiSelectStudentAdmitCard(records);
    }
  });
}
generateMultiSelectStudentAdmitCard(data){
  let idsList=[];
  idsList= data.map(a => a.id);
  console.log("admit card data:",data);
  let date = moment(this.admitCard.get("examDate").value).format("YYYY-MM-DD").toString();
  this.service.generateAdmitCards(idsList , date).subscribe(res=>{
    if(res){
      console.log("generate multi admit card data:",res);
        this.toast.success('Admit cards generated successfully.','SUCCESS');
        this.selection.clear();
        this.loadData();
}});
}

onSubmit() {
  this.submitted = true;
  if (this.admitCard.invalid) {
   //  this.toast.warning('Please fill all details in mandatory fields');
    return;
  }
  else {
      //console.log(moment(this.admitCard.get("examDate").value).format("YYYY-MM-DD HH:mm:ss").toString());
     this.generateAdmitCards();
     
  }
}
filterByClass(value){
  this.limit=500;
  this.offset=0;
  if(this.paginator != null){
   
  this.paginator.pageIndex=0;
  this.paginator.pageSize=500;
  }
     this.searchString = value;
     this.loadData();
}

// showForm(){
//   this.formFlag=true;
// }

}
