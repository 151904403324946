import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';

@Component({
  selector: 'app-unanswered-questions',
  templateUrl: './unanswered-questions.component.html',
  styleUrls: ['./unanswered-questions.component.css']
})
export class UnansweredQuestionsComponent implements OnInit {

 
  public limit: any = 5;
  public offset: any = 0;
  public searchString: any = "";
  public data:any=[];
  ListCount: any;
  public pageSize:any;
  constructor(private service:CommonServiceService,private toast:ToastrService,private route:Router) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
   }

  ngOnInit() 
  { 
    this.loadData();
   
  }
  loadData(){
    this.service.getAllUnAnsweredQuestions(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log(data);
        this.data=data.questions.content;
        this.ListCount = data.questions.totalElements;
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
  }
  pageEvent(event) {
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }

  updateStatus(id,status){

    this.service.updateStatus(id,status).subscribe(data=>{
      if(data){
        console.log("Status Updated: ",data);
        if(status=='Progress'){
          this.toast.success("Status updated.","SUCCESS");
          this.limit=5;
           this.offset=0;
        this.loadData();
       
        }
        else{
          this.toast.success("Status updated.","SUCCESS");
          this.loadData();
         
        }
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
  }
  refreshData(){
    this.limit=5;
    this.offset=0;
    this.data=[];
    this.loadData();
    this.toast.info("Data refreshed.",'SUCCESS');

  }
  markAnswer(id){
    
    this.service.markAnswer(id).subscribe(res=>{
      if(res){
        this.toast.success("Status updated.","SUCCESS");
        this.limit=5;
        this.offset=0;
        this.loadData();
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}

  

}
