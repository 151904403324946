import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';

@Component({
  selector: 'app-upload-gallery-images',
  templateUrl: './upload-gallery-images.component.html',
  styleUrls: ['./upload-gallery-images.component.css']
})
export class UploadGalleryImagesComponent implements OnInit {

  submitted: boolean;
  public level: any = [];
  public selectedCategory:any;
  public selectedFiles: any;
  public catList=[];
  public selectedFiles2: any;
  public finalFilesList:File[]=[];
  images : string[] = [];
  selectedValue: string;    
  uploadGalleryForm = new FormGroup({
    id: new FormControl(''),
    imageFile: new FormControl(''),
    imageFile2: new FormControl('', [Validators.required]),
    category:new FormControl('',[Validators.required])
    
  });
  questions: any=[];

  constructor(private route:Router,private toast:ToastrService,private adminService:CommonServiceService) 
  {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadGalleryCategories();
      
  }

  ngOnInit() {
    this.selectedValue = "";
  }


  loadGalleryCategories(){
    this.adminService.getAllGalleryCategories().subscribe(res=>{
      if(res){
          console.log("allcat", res);
          this.catList=res.gc;
      }
    });
  }

  get f() {
    return this.uploadGalleryForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.uploadGalleryForm.invalid) {
      // this.toa.warning('Please fill all details in mandatory fields');
      return;
    }
    else {
      const data: FormData=new FormData();

      for(let i=0;i<this.finalFilesList.length;i++){
          data.append('file[]', this.finalFilesList[i]);
      }
       
       this.adminService.saveGalleryImages(data).subscribe(res=>
        { 
          console.log("Image response:  ",res);
          if(res)
          {
            this.saveGalleryImageData(res);
                    
          }
        },error=>{
          this.toast.error("Something went wrong !");
    });
    }
  }
  saveGalleryImageData(res){
      this.adminService.saveGalleryDataAfterImages(res,this.selectedCategory).subscribe(res=>{
        if(res){
          console.log("Images Data Saved: ",res);
          // this.toast.success('Image saved successfully.','SUCCESS');
           this.route.navigateByUrl('/dashboard/viewGalleryImages').then(()=>{
              this.toast.success('Images saved successfully.','SUCCESS');
            });
        }
      },error=>{
        this.toast.error("Something went wrong !");
  });
  }
  selectFile(event) {
    this.images=[];
    this.selectedFiles = event.target.files[0];
    this.selectedFiles2 = event.target.files;
    
    console.log(this.selectedFiles2.length); 
    if(this.selectedFiles2.length==0){
      this.selectedFiles=null;
      this.selectedFiles2=null;
      this.images=[];
      this.toast.warning("please select image file !","No File Selected");
    }
    else{
     let fileName=event.target.files[0].name;
      let extn = fileName.split(".").pop();
      console.log(extn);
      let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
      if (!re.exec(fileName)){
        this.toast.warning("Only image files are allowed !","FILE ERROR");
        this.selectedFiles=null;
        this.selectedFiles2=null;
        this.images=[];
        this.uploadGalleryForm.setErrors({ 'invalid': true });
      }
      
      else{
     
        if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
                  var reader = new FileReader();
      
                  reader.onload = (event:any) => {
                    console.log(event.target.result);
                     this.images.push(event.target.result);
                  }
     
                  reader.readAsDataURL(event.target.files[i]);
          }
      }
      this.finalFilesList=this.selectedFiles2;
  
      this.uploadGalleryForm.setErrors(null);
    
      }
     
    }
}
removeImage(url,index){
  this.images = this.images.filter(img => (img != url));
  let fileArray = Array.from(this.finalFilesList);
  fileArray.splice(index,1);
  this.finalFilesList=fileArray;
  if(this.finalFilesList.length==0){
  this.uploadGalleryForm.setErrors({ 'invalid': true });
  this.toast.info("All Images are removed.","INFO");
  }
}

categoryValue(value){
  this.selectedCategory=value;
  console.log("selectedCategory: ",this.selectedCategory);

}

 
}
