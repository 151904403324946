import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-almaktab-al-noor',
  templateUrl: './almaktab-al-noor.component.html',
  styleUrls: ['./almaktab-al-noor.component.css']
})
export class AlmaktabAlNoorComponent implements OnInit {


  public src = "../assets/al.pdf";


  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit() {
  }
  openPDF(src) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
    
  }

}
