import { CommonServiceService } from './../../common-service.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-uploadbook',
  templateUrl: './uploadbook.component.html',
  styleUrls: ['./uploadbook.component.scss']
})
export class UploadbookComponent implements OnInit {

  submitted: boolean;
  public level: any = [];
  public selectedFiles:any= File;
  public selectedImageFiles:any=File;

  public selectedFiles2:any;
  public selectedImageFiles2:any;

  uploadBookForm = new FormGroup({
    id: new FormControl(0),
    name: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    language: new FormControl('', [Validators.required]),
    pages: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    bookFile: new FormControl(''),
    imageFile: new FormControl(''),
    bookFile2: new FormControl('', [Validators.required]),
    imageFile2: new FormControl('', [Validators.required]),
    writtenBy: new FormControl('', [Validators.required]),
  });
  public bookImageBase64: any;
  public pdfFileBase64: any;
  public coverFileBase64: any;
  public imgSrc: any;

  constructor(private route:Router,private toast:ToastrService,private adminService:CommonServiceService,private sanitizer: DomSanitizer) 
  { 
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
  }

  ngOnInit() {
  
  }

  get f() {
    return this.uploadBookForm.controls;
  }

  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}
  onSubmit() {
    this.submitted = true;
    if (this.uploadBookForm.invalid) {
     //  this.toast.warning('Please fill all details in mandatory fields');
      return;
    }
    else {
      // this.uploadBookForm.controls['bookFile'].setValue(this.pdfFileBase64);
      // this.uploadBookForm.controls['imageFile'].setValue(this.coverFileBase64);
      let formData=new FormData();
      formData.append("name",this.uploadBookForm.get('name').value);
      formData.append("category",this.uploadBookForm.get('category').value);
      formData.append("language",this.uploadBookForm.get('language').value);
      formData.append("pages",this.uploadBookForm.get('pages').value);
      formData.append("description",this.uploadBookForm.get('description').value);
      formData.append("writtenBy",this.uploadBookForm.get('writtenBy').value);

       formData.append("bookPdfFile",this.selectedFiles);
       formData.append("bookCoverFile",this.selectedImageFiles);
       this.adminService.saveBook(formData).subscribe(data=>
        { 
          if(data)
          {
            //console.log("saved book ",data);
            this.route.navigateByUrl('/dashboard/viewBookList').then(()=>{
              this.toast.success('Book saved successfully.','SUCCESS');
            });
         
          // console.log("Book Saved: ",data);
          // let bytes = 'data:image/jpg;base64,'+data.book.imageFile;
          // this.imgSrc=bytes;
          }
        },error=>{
         // console.log("error: ",error);
          this.toast.error("Something went wrong !");
    });
    }
  }
  selectFile(event) {
    this.selectedFiles = event.target.files[0];
    console.log('selected file: ',this.selectedFiles);
    this.selectedFiles2 = event.target.files;

    if(this.selectedFiles2.length==0){
      this.selectedFiles=null;
      this.selectedFiles2=null;
      this.toast.warning("please select book file !","No File Selected");
    }
    else{
     let fileName=event.target.files[0].name;
      let extn = fileName.split(".").pop();
      if(extn!='pdf'){
        this.toast.warning("Only PDF files are allowed !","FILE ERROR");
        this.selectedFiles=null;
        this.selectedFiles2=null;
        this.uploadBookForm.setErrors({ 'invalid': true });;
      }
      else{
        if (event.target.files && event.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['application/pdf'];


          if (event.target.files[0].size > max_size) {
              this.toast.warning('Maximum size allowed is ' + max_size / 1000 + 'Mb');
              this.selectedFiles=null;
              this.selectedFiles2=null;
              this.uploadBookForm.setErrors({ 'invalid': true });
              return false;
          }

          // if (!_.includes(allowed_types, event.target.files[0].type)) {
          //   this.toast.warning("Only PDF files are allowed !","FILE ERROR");
          //   this.selectedFiles=null;
          //   this.selectedFiles2=null;
          //   this.uploadBookForm.setErrors({ 'invalid': true });
          //     return false;
          // }
          if (this.selectedFiles) {
            var reader = new FileReader();
    
            reader.onload =this._handleFile.bind(this);
    
            reader.readAsBinaryString(this.selectedFiles);
        }
         
      }
       }
    }
}
_handleFile(readerEvt) {
     var binaryString = readerEvt.target.result;
            this.pdfFileBase64= btoa(binaryString);
            //console.log(btoa(binaryString));
    }
    _handleCover(readerEvt) {
      var binaryString = readerEvt.target.result;
             this.coverFileBase64= btoa(binaryString);
             //console.log(btoa(binaryString));
     }
selectImageFile(event)
 {
  this.selectedImageFiles = event.target.files[0];
  this.selectedImageFiles2 = event.target.files;

    if(this.selectedImageFiles2.length==0){
      this.selectedImageFiles=null;
      this.selectedImageFiles2=null;
      this.toast.warning("please select book cover !","No File Selected");
    }
    
       else{
        if (event.target.files && event.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['image/png', 'image/jpeg'];


          if (event.target.files[0].size > max_size) {
              this.toast.warning('Maximum size allowed is ' + max_size / 1000 + 'Mb');
              this.selectedImageFiles=null;
              this.selectedImageFiles2=null;
              this.uploadBookForm.setErrors({ 'invalid': true });
              return false;
          }

          if (!_.includes(allowed_types, event.target.files[0].type)) {
            this.toast.warning("Only image files are allowed !","FILE ERROR");
            this.selectedImageFiles=null;
            this.selectedImageFiles2=null;
            this.uploadBookForm.setErrors({ 'invalid': true });
              return false;
          }
          if (this.selectedImageFiles) {
            var reader = new FileReader();
    
            reader.onload =this._handleCover.bind(this);
    
            reader.readAsBinaryString(this.selectedImageFiles);
        }
         
      }
       }
     }

}
