import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-createdailyislamicknowlege',
  templateUrl: './createdailyislamicknowlege.component.html',
  styleUrls: ['./createdailyislamicknowlege.component.css']
})
export class CreatedailyislamicknowlegeComponent implements OnInit {

  public ELEMENT_DATA: any;
  public dataSource: any;
  submitted: boolean;
  public selectedFiles:any= File;
  public selectedFiles2:any;
  public allImagesList=[];
  displayedColumns: string[] = ["image_file","created_date","actions"];

  createKnowledge = new FormGroup({
    id: new FormControl(0),
    imageFile: new FormControl(''),
    imageFile2: new FormControl('', [Validators.required]),
  });
  public imageFileBase64: any;
  constructor(private dialog: MatDialog,private route:Router,private toast:ToastrService,private adminService:CommonServiceService,private sanitizer: DomSanitizer) 
  { 
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
  }
  ngOnInit(){
  }
  loadData(){
    this.adminService.getAllImages().subscribe(res=>{
        if(res){
          console.log("All Images Response: ",res);
          this.allImagesList=res.getAllImages;
          this.ELEMENT_DATA = this.allImagesList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        }
    });
  }

  get f() {
    return this.createKnowledge.controls;
  }

 
  onSubmit() {
    this.submitted = true;
    if (this.createKnowledge.invalid) {
     //  this.toast.warning('Please fill all details in mandatory fields');
      return;
    }
    else {
      // this.uploadBookForm.controls['bookFile'].setValue(this.pdfFileBase64);
      // this.uploadBookForm.controls['imageFile'].setValue(this.coverFileBase64);
      let formData=new FormData();
    
       formData.append("file[]",this.selectedFiles);
       this.adminService.saveImageForDailyIslamicKnowledge(formData).subscribe(data=>
        { 
          if(data)
          {
            console.log("saved image url",data[0]);
            this.adminService.saveImageDataAfterImageUpload(data[0]).subscribe(res=>{
                if(res){
                    console.log("after data saved: ",res);
                  this.toast.success('Image saved successfully.','SUCCESS');
                  this.selectedFiles=null;
              this.selectedFiles2=null;
              this.createKnowledge.setErrors({ 'invalid': true });
              this.loadData();
            //             this.route.navigateByUrl('/dashboard/viewBookList').then(()=>{
            //              this.toast.success('Book saved successfully.','SUCCESS');
            // });
                }
            });

           
         
         
          }
        },error=>{
         // console.log("error: ",error);
          this.toast.error("Something went wrong !");
    });
    }
  }
  selectFile(event) {
    this.selectedFiles = event.target.files[0];
    console.log('selected file: ',this.selectedFiles);
    this.selectedFiles2 = event.target.files;

    if(this.selectedFiles2.length==0){
      this.selectedFiles=null;
      this.selectedFiles2=null;
      this.toast.warning("please select image file !","No File Selected");
    }
    else{
      let fileName=event.target.files[0].name;
      let extn = fileName.split(".").pop();
      console.log(extn);
      let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
      if (!re.exec(fileName)){
        this.toast.warning("Only Images files are allowed !","FILE ERROR");
        this.selectedFiles=null;
        this.selectedFiles2=null;
        this.createKnowledge.setErrors({ 'invalid': true });
      }
      else{
        if (event.target.files && event.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['application/image'];


          if (event.target.files[0].size > max_size) {
              this.toast.warning('Maximum size allowed is ' + max_size / 1000 + 'Mb');
              this.selectedFiles=null;
              this.selectedFiles2=null;
              this.createKnowledge.setErrors({ 'invalid': true });
              return false;
          }

          // if (!_.includes(allowed_types, event.target.files[0].type)) {
          //   this.toast.warning("Only PDF files are allowed !","FILE ERROR");
          //   this.selectedFiles=null;
          //   this.selectedFiles2=null;
          //   this.uploadBookForm.setErrors({ 'invalid': true });
          //     return false;
          // }
          if (this.selectedFiles) {
            var reader = new FileReader();
    
            reader.onload =this._handleFile.bind(this);
    
            reader.readAsBinaryString(this.selectedFiles);
        }
         
      }
       }
    }
}
_handleFile(readerEvt) {
  var binaryString = readerEvt.target.result;
         this.imageFileBase64= btoa(binaryString);
         //console.log(btoa(binaryString));
 }
 openDialog(element) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteRecord(element);
    }
  });
}

deleteRecord(element){

  this.adminService.deleteImageById(element.id).subscribe(data=>{
    if(data){
      this.toast.success('Record deleted successfully.','SUCCESS');
    
      this.loadData();
     
    }
  },error=>{
    this.toast.error('Something went wrong !','ERROR')
  })
}


}
