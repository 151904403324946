import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { CommonServiceService } from '../common-service.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.css']
})
export class AdmissionComponent implements OnInit {
  maxDob: Date;
  // minDate = new Date();
  public adharVal:any;
  submitted: boolean;
  public level: any = [];
  public selectedFiles:any= File;
  public selectedadharImages:any=File;

  public selectedFiles2:any;
  public selectedadharImages2:any;

  studentReg = new FormGroup({
    id: new FormControl(0),
    name: new FormControl('', [Validators.required]),
    fname: new FormControl('', [Validators.required]),
    mname: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    mnumber: new FormControl('', [Validators.required]),
    sClass: new FormControl('', [Validators.required]),
    jurisprudence: new FormControl('', [Validators.required]),
    //email: new FormControl('', [Validators.email,Validators.required]),
    adharPdfFile: new FormControl(''),
    adharPdfFile2: new FormControl('', [Validators.required]),
    adhaar: new FormControl('', [Validators.required]),
  });
  public bookImageBase64: any;
  public pdfFileBase64: any;
  public coverFileBase64: any;
  public imgSrc: any;

  constructor(private snackBar: MatSnackBar,private route:Router,private toast:ToastrService,private adminService:CommonServiceService,private sanitizer: DomSanitizer) 
  { 
    const today = new Date();
  this.maxDob = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  }

  ngOnInit() {
  
  }

  get f() {
    return this.studentReg.controls;
  }

  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}
  onSubmit() {
    this.submitted = true;
    if (this.studentReg.invalid) {
     //  this.toast.warning('Please fill all details in mandatory fields');
      return;
    }
    else {

      let check = this.studentReg.get('adhaar').hasError('invalid');
      if(check){
        this.checkValue(this.studentReg.get('adhaar').value);
      }
      else
        {

      let enumber="EN";
      enumber= enumber.concat(Math.floor(100000 + Math.random() * 900000)+"");
      // this.uploadBookForm.controls['adharPdfFile'].setValue(this.pdfFileBase64);
      // this.uploadBookForm.controls['adharImage'].setValue(this.coverFileBase64);
      let formData=new FormData();
      formData.append("name",this.studentReg.get('name').value);
      formData.append("fname",this.studentReg.get('fname').value);
      formData.append("mname",this.studentReg.get('mname').value);
      formData.append("dob",moment(this.studentReg.get("dob").value).add(1, 'days').format("YYYY-MM-DD HH:mm:ss").toString());
      formData.append("mnumber",this.studentReg.get('mnumber').value);
      formData.append("address",this.studentReg.get('address').value);
     // formData.append("email",this.studentReg.get('email').value);
      formData.append("class",this.studentReg.get('sClass').value);
      formData.append("adhar",this.studentReg.get('adhaar').value);
      formData.append("jurisprudence",this.studentReg.get('jurisprudence').value);
      formData.append("enumber",enumber);

       formData.append("adharPdfFile",this.selectedFiles);
       this.adminService.saveAdmission(formData).subscribe(data=>
        { 
          if(data)
          {
            console.log("saved admission ",data[0]);
            let object=data[0];
            delete object['dob']
            object["enrollmentNumber"]=data[0].enrollment_number;

                let msg="Your details has been registered successfully.your Enrolled Number is: ( "+object.enrollmentNumber+" ).Please save this enrollment number."
                this.studentReg.disable();
                this.showSnackbarTopPosition(msg,"Done");
    
    }
        },error=>{
         // console.log("error: ",error);
          this.toast.error("Something went wrong !");
    });
  }
    }
  }
  showSnackbarTopPosition(content, action) {
    let sb=this.snackBar.open(content, action, {
      panelClass: ["custom-style"],
      verticalPosition: "top", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
    sb.onAction().subscribe(() => {
      this.route.navigateByUrl('/home').then(()=>{
          this.toast.success('Student data saved successfully.','SUCCESS');
        });
    });
  }


  selectFile(event) {
    this.selectedFiles = event.target.files[0];
    console.log('selected file: ',this.selectedFiles);
    this.selectedFiles2 = event.target.files;

    if(this.selectedFiles2.length==0){
      this.selectedFiles=null;
      this.selectedFiles2=null;
      this.toast.warning("please adhaar file !","No File Selected");
    }
    else{
     let fileName=event.target.files[0].name;
      let extn = fileName.split(".").pop();
      console.log(extn);
    
      if( (extn=='pdf') || (extn=='jpeg') || (extn=='jpg')|| (extn=='png') || (extn=='gif')){
        if (event.target.files && event.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['application/pdf'];


          if (event.target.files[0].size > max_size) {
              this.toast.warning('Maximum size allowed is ' + max_size / 1000 + 'Mb');
              this.selectedFiles=null;
              this.selectedFiles2=null;
              this.studentReg.setErrors({ 'invalid': true });
              return false;
          }

          // if (!_.includes(allowed_types, event.target.files[0].type)) {
          //   this.toast.warning("Only PDF files are allowed !","FILE ERROR");
          //   this.selectedFiles=null;
          //   this.selectedFiles2=null;
          //   this.uploadBookForm.setErrors({ 'invalid': true });
          //     return false;
          // }
          if (this.selectedFiles) {
            var reader = new FileReader();
    
            reader.onload =this._handleFile.bind(this);
    
            reader.readAsBinaryString(this.selectedFiles);
        }
         
      }



   
      }
      else{
        this.toast.warning("Only PDF and Image files are allowed !","FILE ERROR");
        this.selectedFiles=null;
        this.selectedFiles2=null;
        this.studentReg.setErrors({ 'invalid': true });
       }
    }
}
_handleFile(readerEvt) {
     var binaryString = readerEvt.target.result;
            this.pdfFileBase64= btoa(binaryString);
            //console.log(btoa(binaryString));
    }
    _handleCover(readerEvt) {
      var binaryString = readerEvt.target.result;
             this.coverFileBase64= btoa(binaryString);
             //console.log(btoa(binaryString));
     }
selectadharImage(event)
 {
  this.selectedadharImages = event.target.files[0];
  this.selectedadharImages2 = event.target.files;

    if(this.selectedadharImages2.length==0){
      this.selectedadharImages=null;
      this.selectedadharImages2=null;
      this.toast.warning("please select book cover !","No File Selected");
    }
    
       else{
        if (event.target.files && event.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['image/png', 'image/jpeg'];


          if (event.target.files[0].size > max_size) {
              this.toast.warning('Maximum size allowed is ' + max_size / 1000 + 'Mb');
              this.selectedadharImages=null;
              this.selectedadharImages2=null;
              this.studentReg.setErrors({ 'invalid': true });
              return false;
          }

          if (!_.includes(allowed_types, event.target.files[0].type)) {
            this.toast.warning("Only image files are allowed !","FILE ERROR");
            this.selectedadharImages=null;
            this.selectedadharImages2=null;
            this.studentReg.setErrors({ 'invalid': true });
              return false;
          }
          if (this.selectedadharImages) {
            var reader = new FileReader();
    
            reader.onload =this._handleCover.bind(this);
    
            reader.readAsBinaryString(this.selectedadharImages);
        }
         
      }
       }
     }

     adhaarValidate(event){
      let val = event.target.value;
       val = val.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join("-");
       this.adharVal=val;
       console.log("Before Regex: ",this.adharVal);
       val=val.replace(/[^0-9]/g, '');
       console.log("After Regex: ",val);
       this.studentReg.get('adhaar').setValue(val);
     }

     filterSelection(val){

      console.log(val.value);

     }
     onChange(event){
       console.log(event.value);
     }


     onKey(event)
      {
        let inputValue = event.target.value;
        if(inputValue != "" && inputValue.length == 12){
          console.log(inputValue,inputValue.length);
          this.adminService.checkAdhaarExistence(inputValue).subscribe(res=>{
            if(res){
              console.log("checkAdhaarExistence :",res);
              if(res.adhaarExistence != null){
                this.toast.warning("Adhaar number already exists !",'Already Exists');
                this.studentReg.get('adhaar').setErrors({'incorrect': true});
                //this.studentReg.get('mnumber').updateValueAndValidity();
                
              }
              else{
                this.studentReg.get('adhaar').setErrors(null);
                this.studentReg.get('adhaar').updateValueAndValidity();
              }
            }
          },error=>{
            // console.log("error: ",error);
             this.toast.error("Something went wrong !");
       });
        }
        else{
          console.log("Empty !");
        }
      }
      checkValue(value)
      {
        let inputValue = value;
        if(inputValue != "" && inputValue.length == 12){
          console.log(inputValue,inputValue.length);
          this.adminService.checkAdhaarExistence(inputValue).subscribe(res=>{
            if(res){
              console.log("checkAdhaarExistence :",res);
              if(res.adhaarExistence != null){
                this.toast.warning("Adhaar number already exists !",'Already Exists');
                this.studentReg.get('adhaar').setErrors({'incorrect': true});
                //this.studentReg.get('mnumber').updateValueAndValidity();
                
              }
              else{
                this.studentReg.get('adhaar').setErrors(null);
                this.studentReg.get('adhaar').updateValueAndValidity();
              }
            }
          },error=>{
            // console.log("error: ",error);
             this.toast.error("Something went wrong !");
       });
        }
        else{
          console.log("Empty !");
        }
      }

}

