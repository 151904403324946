import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-see-gallery-images',
  templateUrl: './see-gallery-images.component.html',
  styleUrls: ['./see-gallery-images.component.css']
})
export class SeeGalleryImagesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public limit: any = 10;
  public galleryListCount: any;
  public pageSize:any;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];

  public galleryList: any = [];
  displayedColumns: string[] = ["select","name", "imageFile", "category","createdDate","actions"];

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
   }

  ngOnInit() {
  }

  loadData(){
    this.service.getAllGalleryImages(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("gallery: ",data);
          this.galleryList = data.gallery.content;
          this.ELEMENT_DATA = this.galleryList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.galleryListCount = data.gallery.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }

  getImageURL(data){
    
    return "https://gallery.faizanetajushshariya.com/"+data;
  }

  refreshData(){
    this.limit=15;
    this.offset=0;
    this.galleryList=[];
    this.refresh();
  }
  refresh(){
    this.service.getAllGalleryImages(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("gallery: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.galleryList = data.gallery.content;
        this.ELEMENT_DATA = this.galleryList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.galleryListCount = data.gallery.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}

  
  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
 
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }

  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
       this.deleteGalleryImage(element);
      }
    });
  }

  deleteGalleryImage(element){
    let idsList=[];
    idsList.push(element.id)
    this.service.deleteGalleryData(idsList).subscribe(data=>{
      if(data){
        console.log("deleted by list",data);
        this.service.deleteGalleryImageById(element.id).subscribe(res=>{
          if(res){
            this.toast.success('Image deleted successfully.','SUCCESS');
              if(this.selection.hasValue()){
                this.selection.clear();
              }
              this.loadData();
            }
        });
        
       
      }
    },error=>{
      this.toast.error('Something went wrong !','ERROR')
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
deleteSelection(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForDeletion(this.records);
}
confirmForDeletion(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure want to delete selected items?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.deleteMultiSelect(records);
    }
  });
}
deleteMultiSelect(data)
{
  let idsList=[];
  idsList= data.map(a => a.id);
  console.log("multi images data:",idsList)
  this.service.deleteGalleryData(idsList).subscribe(res=>{
    if(res)
    {
      console.log("multi image response",res);
         this.service.deleteMultiSelectGalleryImages(data).subscribe(resp=>{
            if(resp)
            {
                 this.toast.success('Records deleted successfully.','SUCCESS');
                 this.selection.clear();
                 this.loadData();
            }
      });
    }
  });
}
categoryValue(event,element){
  console.log("event: ",event);
  
  element.category=event;
  console.log("element value change: ",element);
}




}
