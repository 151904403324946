import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss',
  "../../assets/vendor/bootstrap/css/bootstrap.min.css",
"../../assets/vendor/bootstrap-icons/bootstrap-icons.css",
"../../assets/vendor/boxicons/css/boxicons.min.css",
"../../assets/vendor/glightbox/css/glightbox.min.css",
"../../assets/vendor/remixicon/remixicon.css",
"../../assets/vendor/swiper/swiper-bundle.min.css",
"../../assets/css/style.css"],
encapsulation: ViewEncapsulation.None, 
  
})



export class HomeComponent implements OnInit {

  constructor(private router:Router)
   {
    setTimeout(() => {
    this.router.navigate(["/"]).then(result=>{window.location.href = 'https://dashboard.faizanetajushshariya.com';});
  }, 1000);
   
    const node = document.createElement('script');
    node.src = '../../assets/js/main.js';
    node.type = 'text/javascript';
    node.async = true;
    // node.id = 'main';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
   }
    ngOnInit() {
      setTimeout(() => {
        this.router.navigate(["/"]).then(result=>{window.location.href = 'https://dashboard.faizanetajushshariya.com';});
      }, 1000);
  //       this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       // console.log()
  //       //   if (document.getElementById('main') !=null) {
  //       //       document.getElementById('main').remove();
  //       //   }
  //         const node = document.createElement('script');
  //         node.src = '../../assets/js/main.js';
  //         node.type = 'text/javascript';
  //         node.async = false;
  //         // node.id = 'main';
  //         node.charset = 'utf-8';
  //         document.getElementsByTagName('head')[0].appendChild(node);
  //     }
  //     else{
        
  //     }
  // });
  
  }
  goToLogin()
  {
    this.router.navigateByUrl('/login').then(()=>{
  
    });
  }

  goToBooksSecton(){
    this.router.navigateByUrl('/books').then(()=>
    {
     
     
    });
  }
  goToFatawaSecton(){
    this.router.navigateByUrl('/viewFatawaList').then(()=>
    {
     window.location.reload();
     
    });
  }

}
