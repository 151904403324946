import { DonationOptionsComponent } from './../donation-options/donation-options.component';
import { DonationRequestFormComponent } from './../donation-request-form/donation-request-form.component';
import { CommonServiceService } from './../common-service.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';


declare var Razorpay: any;

@Component({
  selector: 'app-paymentpage',
  templateUrl: './paymentpage.component.html',
  styleUrls: ['./paymentpage.component.scss']
})
export class PaymentpageComponent  {

  

  form: any = {}; 
  paymentId: string;
  error: string;
 
  constructor(private toast:ToastrService,public dialog: MatDialog,private router:Router,private orderService: CommonServiceService) {

  }

  options = {
  "key": "",
  "amount": "", 
  "name": "FaizaneTajushshariya",
  "description": "Donation to FaizaneTajushshariya",
  "image": "../../assets/icon.png",
  "order_id":"",
  "handler": function (response){
      var event = new CustomEvent("payment.success", 
          {
              detail: response,
              bubbles: true,
              cancelable: true
          }
      );    
      window.dispatchEvent(event);
  }
  ,
  "prefill": {
  "name": "",
  "email": "",
  "contact": ""
  },
  "notes": {
  "address": ""
  },
  "theme": {
  "color": "#3399cc"
  }
  };

  onSubmit(): void {
      if(this.form.amount>=100000){
          this.toast.warning("You can not donate more than 1Lakh at a time.");
      }
      else{

     
      this.paymentId = ''; 
      this.error = ''; 
      this.orderService.createOrder(this.form).subscribe(
      data => {
          this.options.key = data.secretKey;
          this.options.order_id = data.razorpayOrderId;
          this.options.amount = data.applicationFee; 
          this.options.prefill.name = this.form.name;
          this.options.prefill.email = this.form.email;
          this.options.prefill.contact = this.form.phone;
          var rzp1 = new Razorpay(this.options);
          rzp1.open();
                     
          rzp1.on('payment.failed', function (response){    
              // Todo - store this information in the server
              console.log(response.error.code);    
              console.log(response.error.description);    
              console.log(response.error.source);    
              console.log(response.error.step);    
              console.log(response.error.reason);    
              console.log(response.error.metadata.order_id);    
              console.log(response.error.metadata.payment_id);
              this.error = response.error.reason;
          }
          );
      }
      ,
      err => {
          this.error = err.error.message;
          console.log("Error");
      }
      );
    }
  }

  @HostListener('window:payment.success', ['$event']) 
  onPaymentSuccess(event): void {
      this.orderService.updateOrder(event.detail).subscribe(
      data => {
          this.paymentId = data.message;
          
      }
      ,
      err => {
          this.error = err.error.message;
          console.log("Error");
      }
      );
  }

  home(){
    this.router.navigateByUrl('/home').then(()=>{
        window.location.reload();
    });
}
login(){
  this.router.navigateByUrl('/login');
}

seeDonationRecord(){
    const dialogRef = this.dialog.open(DonationOptionsComponent,{
       width: '250px',
        disableClose: false 
      });

}
 

}
