import { CommonServiceService } from './../../common-service.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-uploadfatawa',
  templateUrl: './uploadfatawa.component.html',
  styleUrls: ['./uploadfatawa.component.css']
})
export class UploadfatawaComponent implements OnInit {

  submitted: boolean;
  public level: any = [];
  public selectedFiles: any;
  public selectedFiles2: any;
  selectedValue: string;    
  uploadFatawaForm = new FormGroup({
    id: new FormControl(''),
    subject: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    // questName: new FormControl(''),
    // quesAddress: new FormControl(''),
    // questions: new FormControl('', [Validators.required]),
    // fatawaBy: new FormControl('', [Validators.required]),
    // quesEmail: new FormControl(''),
    // quesContact: new FormControl(''),
    fatawaFile: new FormControl(''),
    fatawaFile2: new FormControl('', [Validators.required]),
    
  });
  questions: any=[];

  constructor(private route:Router,private toast:ToastrService,private adminService:CommonServiceService) 
  {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
      //this.loadUnAnsweredQuestions();
  }

  ngOnInit() {
    this.selectedValue = "";
  }

  loadUnAnsweredQuestions(){
    this.adminService.getAllUnAnsweredQuestionsForFatawa().subscribe(res=>{
        if(res){
          console.log("Questions For Fatawa: ",res);
          this.questions=res.questions;
        }
    });
  }
  

  get f() {
    return this.uploadFatawaForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.uploadFatawaForm.invalid) {
      // this.toa.warning('Please fill all details in mandatory fields');
      return;
    }
    else {
      let formData=new FormData();
      formData.append("subject",this.uploadFatawaForm.get('subject').value);
      formData.append("category",this.uploadFatawaForm.get('category').value);
      formData.append("fatawaPdfFile",this.selectedFiles);

       this.adminService.saveFatawa(formData).subscribe(data=>
        { 
          if(data)
          {
            console.log("Saved Fatawa: ",data);
            this.route.navigateByUrl('/dashboard/viewFatawaList').then(()=>{
              this.toast.success('Fatawa saved successfully.','SUCCESS');
            });
         
          }
        },error=>{
          this.toast.error("Something went wrong !");
    });
    }
  }
  selectFile(event) {
    this.selectedFiles = event.target.files[0];
    this.selectedFiles2 = event.target.files;
    console.log(this.selectedFiles2.length); 
    if(this.selectedFiles2.length==0){
      this.selectedFiles=null;
      this.selectedFiles2=null;
      this.toast.warning("please select book file !","No File Selected");
    }
    else{
     let fileName=event.target.files[0].name;
      let extn = fileName.split(".").pop();
      if(extn!='pdf'){
        this.toast.warning("Only PDF files are allowed !","FILE ERROR");
        this.selectedFiles=null;
        this.selectedFiles2=null;
        this.uploadFatawaForm.setErrors({ 'invalid': true });;
      }
      
      else{
        this.uploadFatawaForm.setErrors(null);
      }
    }
}

// onChange(change: MatSelect) {
//    console.log(change);
//    if(change.value!=""){
  
//     this.uploadFatawaForm.patchValue({
//       questName: this.questions[0].name,
//       quesAddress: this.questions[0].address,
//       quesEmail:this.questions[0].email,
//       quesContact:this.questions[0].contact,
//     });

//    }
//    else{
//     this.uploadFatawaForm.patchValue({
//       questName: "",
//       quesAddress: "",
//       quesEmail:"",
//       quesContact:"",
//     });
//    }
// }
}
