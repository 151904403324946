import { PaymentpageComponent } from './paymentpage/paymentpage.component';
import { FatawaListComponent } from './fatawa-list/fatawa-list.component';
import { BooksComponent } from './books/books.component';
import { NgModule } from '@angular/core';
import { DonationpublicviewComponent } from './donationpublicview/donationpublicview.component';
import { Routes, RouterModule } from '@angular/router';
import { AlmaktabAlNoorComponent } from './almaktab-al-noor/almaktab-al-noor.component';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { BookDetailsComponent } from './book-details/book-details.component';
import { AdmissionComponent } from './admission/admission.component';
import { DownloadadmitcardComponent } from './downloadadmitcard/downloadadmitcard.component';
import { SearchStudentComponent } from './search-student/search-student.component';
import { ViewResultComponent } from './view-result/view-result.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'donate', component: PaymentpageComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'Almaktab-al-Noor', component: AlmaktabAlNoorComponent },
  { path: 'books', component: BooksComponent },
  { path: 'admission', component: AdmissionComponent },
  { path: 'downloadAdmitCard', component: DownloadadmitcardComponent },
  { path: 'viewResult', component: ViewResultComponent},
  { path: 'searchStudent', component: SearchStudentComponent },
  { path: 'publicDonationRecord', component: DonationpublicviewComponent }
  ,{
    path: 'bookDetails/:id',
    component: BookDetailsComponent
  },
  { path: 'bookDetails', pathMatch: 'full', redirectTo: '/books' }
  ,{
    path: 'viewFatawaList',
    component: FatawaListComponent
  },
  { path: 'dashboard', pathMatch: 'full', redirectTo: '/dashboard/home' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
