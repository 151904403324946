import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent  {
  
   username : string;
   pass : string;

  constructor(private toast:ToastrService,private route:Router) { 
   
  }

  onSubmit()
  {
    if(this.username===localStorage.getItem("username").toString() && this.pass===localStorage.getItem("password").toString() )
    {
      
      this.route.navigateByUrl('/dashboard/home').then(()=>
      {
        window.location.reload();
        this.toast.success('Login successfully.','SUCCESS');
        localStorage.setItem('Yes','YES');
         
      
      });
      
    }
    else{
      this.toast.error('Check Username or Password !','FAILED');
    }
    
  }
  backToHome(){
    this.route.navigateByUrl('/home').then(()=>{
      const node = document.createElement('script');
      node.src = '../../assets/js/main.js';
      node.type = 'text/javascript';
      node.async = true;
      // node.id = 'main';
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }


}
