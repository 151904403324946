import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-declare-result',
  templateUrl: './declare-result.component.html',
  styleUrls: ['./declare-result.component.css']
})
export class DeclareResultComponent implements OnInit {

   
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
   public mySet = new Set();
   public finalArray=[];
  selection = new SelectionModel<Element>(true, []);
  records: any[] = [];


  public limit: any = 500;
  public offset: any = 0;
  public searchString: any = "";
  public ELEMENT_DATA: any;
  public dataSource: any;
  public pageSize:any;
  public studentList: any = [];
  // displayedColumns: string[] = ["select","enrollment_number","name","fname","mname","address","dob","mnumber","jurisprudence","email",
  // "adhaar","adhar_pdf_file","s_class","admisson_status","admit_card_generated","created_date","result","remarks"];


  displayedColumns: string[] = ["enrollment_number","name","mnumber","s_class","admisson_status","result","remarks"];
  
  public studentListCount: any;

  constructor(private dialog: MatDialog,private toast:ToastrService,private route:Router,private service:CommonServiceService) {
    let value = localStorage.getItem("Yes");
    console.log(value);
    if(value ==null || value==undefined || value=="" )
    {
      this.route.navigateByUrl('/login').then(()=>
      {
        window.location.reload()
      });
    } 
    this.loadData();
   }

  ngOnInit() {
  
  }
  refreshData(){
    this.limit=500;
    this.offset=0;
    this.studentList=[];
    this.refresh();
  }
  refresh(){
    this.service.getAllStudentsByResultStatus(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log("students: ",data);
        if(this.selection.hasValue()){
          this.selection.clear();
        }
        this.studentList = data.students.content;
        this.ELEMENT_DATA = this.studentList;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.studentListCount = data.students.totalElements;
        this.toast.info("Data Refreshed.",'SUCCESS');
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}
 
  loadData(){
    this.service.getAllStudentsByResultStatus(this.offset,this.limit,this.searchString).subscribe(data=>{
        if(data){
          console.log("students: ",data);
          this.studentList = data.students.content;
          this.ELEMENT_DATA = this.studentList;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.studentListCount = data.students.totalElements;
        }
    },error=>{
      this.toast.error("Something went wrong !");
    });
  }
  viewPDF(element){
    window.open("https://faizanetajushshariya.com/rashid/"+element.adharPdfFile, '_blank');

  }


  
  pageEvent(event) {
    if(this.selection.hasValue()){
      this.selection.clear();
    }
 
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
 
  doAdmission(){
  this.records=[];
  this.selection.selected.forEach(item => {
  this.records.push(item);
      });
  this.confirmForAdmission(this.records);
}
confirmForAdmission(records) {
  const dialogRef = this.dialog.open(ConfirmDialogComponent,{
    data:{
      message: 'Are you sure ?',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
     this.doMultiSelectStudentAdmission(records);
    }
  });
}
doMultiSelectStudentAdmission(data){
  let idsList=[];
  idsList= data.map(a => a.id);
  console.log("multi book data:",data);
  this.service.updateAdmissionStatus(idsList).subscribe(res=>{
    if(res){
       
        this.toast.success('Admission done successfully.','SUCCESS');
        this.selection.clear();
        this.loadData();
}});
}


resultValue(value,object){
 
  //console.log("value",value+"      Before:",object);

  if(value!= ""){
    if(value == "Pass"){
     if(this.mySet.has(object)){
      this.mySet.delete(object);
      object.result="1";
      this.mySet.add(object);

    }
    else{
      object.result="1";
      this.mySet.add(object);
    }
  }
    else{
      if(this.mySet.has(object)){
        this.mySet.delete(object);
        object.result="0";
        this.mySet.add(object);
  
      }
      else{
        object.result="0";
        this.mySet.add(object);
      }
    }
  }
  else{
    if(this.mySet.has(object)){
      this.mySet.delete(object);
      object.result="-1";
      object.remarks="";
      // this.mySet.add(object);

    }
    else{
      object.result="-1";
      object.remarks="";
      // this.mySet.add(object);
    }
  }
  //console.log("value",value+"      After:",object);

  if(this.mySet.size == 0){
    this.finalArray=[];
  }

  this.finalArray = Array.from(this.mySet.values());
  console.log("Final List:",this.finalArray);
}

declare(){
  console.log("Declare:",this.finalArray);
  this.service.declareResult(this.finalArray).subscribe(res=>{
      if(res){
        this.toast.success("Result Declared.","SUCCESS");
        this.finalArray=[];
        this.mySet = new Set();
        this.loadData();
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
}

}
