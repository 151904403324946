import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private route:Router) {
    this.route.navigate(["/"]).then(result=>{window.location.href = 'https://dashboard.faizanetajushshariya.com';});
   }

  ngOnInit() {
    this.route.navigate(["/"]).then(result=>{window.location.href = 'https://dashboard.faizanetajushshariya.com';});
  }

  goToHome()
  {
    this.route.navigateByUrl('https://dashboard.faizanetajushshariya.com').then(()=>{
      const node = document.createElement('script');
      node.src = '../../assets/js/main.js';
      node.type = 'text/javascript';
      node.async = false;
      // node.id = 'main';
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }
}
