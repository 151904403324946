import { CommonServiceService } from './../../common-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { finalize, first } from 'rxjs/operators';

@Component({
  selector: 'app-dashboardhome',
  templateUrl: './dashboardhome.component.html',
  styleUrls: ['./dashboardhome.component.css']
})
export class DashboardhomeComponent implements OnInit {

data:any;
top5Questions:any=[];
today: number = Date.now();
isLoading: boolean=false;

  
  constructor(private router:Router,private service:CommonServiceService) 
  { 
    // let value = localStorage.getItem("Yes");
    //     console.log(value);
    //     if(value ==null || value==undefined || value=="" )
    //     {
    //       this.router.navigateByUrl('/login').then(()=>
    //       {
    //         window.location.reload()
    //       });
    //     }
    setInterval(() => {this.today = Date.now()}, 1);
      this.loadData();
  }

  ngOnInit(){}
  loadData(){
    
    this.isLoading=true;
    this.service.getDashboardData().pipe(
      finalize(() => (this.isLoading = false)),
      first()
    ).subscribe(data=>{
        if(data){
          console.log("DashBoard data:",data);
          this.data=data;
          this.top5Questions=data.top5Questions.content;
        }
    });
  }
 
}
