import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from '../common-service.service';

@Component({
  selector: 'app-view-result',
  templateUrl: './view-result.component.html',
  styleUrls: ['./view-result.component.css']
})
export class ViewResultComponent implements OnInit {
  public studentList:any;
  public flag:any;
  constructor(private toast:ToastrService,private service:CommonServiceService) { }

  ngOnInit() {
  }

  onSubmit(event: any) {
    let val=event.target.enumber.value;
    if(val==""){
      this.toast.info("Please enter your enrolled number !");
      this.studentList= null;
      this.flag=false;
    }
    else{
      this.service.searchStudentBasedOnEID(val).subscribe(res=>{
        if(res){
          console.log("search student: ",res);

          if(res.searchStudentBasedOnEID!=null){
            this.studentList=res.searchStudentBasedOnEID;
            console.log("student list: ",this.studentList);
            this.flag=false;
          }
          else{
            this.flag=true;
            this.studentList=null;
          }
         
        }
      },err=>{
        this.toast.warning("Something went wrong !");
      });
      //this.toast.success("Success");
    }
 }


}
