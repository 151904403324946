import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit { 
  constructor(private router:Router)
   {
    localStorage.setItem('username','rahat@78692');
    localStorage.setItem('password','rahat@78692');
   }
    ngOnInit() {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // console.log()
          //   if (document.getElementById('main') !=null) {
          //       document.getElementById('main').remove();
          //   }
            const node = document.createElement('script');
            node.src = '../assets/js/main.js';
            node.type = 'text/javascript';
            node.async = false;
            node.id = 'main';
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);

        }
    });

  }

}
