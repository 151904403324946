import { ToastrService } from 'ngx-toastr';
import { CommonServiceService } from 'src/app/common-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-answered-questions',
  templateUrl: './answered-questions.component.html',
  styleUrls: ['./answered-questions.component.css']
})
export class AnsweredQuestionsComponent implements OnInit {

  public limit: any = 5;
  public offset: any = 0;
  public searchString: any = "";
  public data:any=[];
  ListCount: any;
  public pageSize:any;
  constructor(private service:CommonServiceService,private toast:ToastrService,private router:Router) { let value = localStorage.getItem("Yes");
  console.log(value);
  if(value ==null || value==undefined || value=="" )
  {
    this.router.navigateByUrl('/login').then(()=>
    {
      window.location.reload()
    });
  }}

  ngOnInit() 
  { 
    this.loadData();
   
  }
  loadData(){
    this.service.getAllAnsweredQuestions(this.offset,this.limit,this.searchString).subscribe(data=>{
      if(data){
        console.log(data);
        this.data=data.questions.content;
        this.ListCount = data.questions.totalElements;
      }
  },error=>{
    this.toast.error("Something went wrong !");
  });
  }
  pageEvent(event) {
    this.limit=event.pageSize;
    this.offset=event.pageIndex;

    this.loadData();
  }
  refreshData(){
    this.limit=5;
    this.offset=0;
    this.data=[];
    this.loadData();
    this.toast.info("Data refreshed.",'SUCCESS');

  }

}
